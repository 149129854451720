/** @format */

import React, { Component } from 'react';
import { Droppable, Draggable, DroppableProvided } from 'react-beautiful-dnd';

export class ColorList extends Component {
	static defaultProps = {
		isCombineEnabled: false,
	};
	renderBoard = (dropProvided: DroppableProvided) => {
		const { colors, setSelectedColor, colorMap, setEditColor, getAdjacentColors } =
			this.props;

		return (
			<div className='colors__list' ref={dropProvided.innerRef}>
				{colors.map((color, index) => (
					<Draggable key={color.line} draggableId={color.line} index={index}>
						{(dragProvided, dragSnapshot) => (
							<div
								{...dragProvided.dragHandleProps}
								{...dragProvided.draggableProps}
								ref={dragProvided.innerRef}
								key={color.name}
							>
								<div
									className='color-block'
									style={{
										backgroundColor: color.base,
										borderColor: color.line,
										borderWidth: '5px',
										borderStyle: 'solid',
									}}
									onClick={() => {
										setSelectedColor(color);
										setEditColor(color)
										getAdjacentColors(colorMap, index)
									}}
								>
									{' '}
								</div>
							</div>
						)}
					</Draggable>
				))}
				{dropProvided.placeholder}
			</div>
		);
	};

	render() {
		const { listId, listType, internalScroll, isCombineEnabled } = this.props;

		return (
			<Droppable
				droppableId={listId}
				type={listType}
				direction='horizontal'
				isCombineEnabled={isCombineEnabled}
			>
				{(dropProvided, dropSnapshot) => this.renderBoard(dropProvided)}
			</Droppable>
		);
	}
}
