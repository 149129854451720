/** @format */

import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { checkAuth } from '../pages/auth/helpers.js';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Nav = ({
	tabs,
	target,
	setTarget,
	setPage,
	page,
	isAuth,
	setIsAuth,
	user,
	setUser,
}) => {
	const navigate = useNavigate();
	const checkRef = useRef();
	const params = useParams();

	const [isVerified, setIsVerified] = useState(true);
	const [dropDown, setDropDown] = useState(false);
	const [role, setRole] = useState(null)

	useEffect(() => {
		checkIsLoggedIn();
	}, [isAuth]);

	const clicky = (val) => {
		setTarget(val);
	};

	const unCheck = () => {
		checkRef.current.checked = false;
	};

	const checkIsLoggedIn = async () => {
		const userObj = await checkAuth();
		setIsAuth(userObj.loggedIn);
		setUser(userObj.username);
		setIsVerified(userObj.isVerified);
		setRole(userObj.role)
	};

	const logout = () => {
		console.log('logout');
		navigate('/logout');
	};

	return (
		<Fragment>
			<nav className='nav'>
				<label className='nav__icon' for='navi-toggle'>
					<input
						type='checkbox'
						className='nav__icon--checkbox'
						id='navi-toggle'
						ref={checkRef}
					/>
					<label for='navi-toggle' className='nav__icon--button'>
						&nbsp;
					</label>
					<div className='nav__icon--nav'>
						<Link to='/'>
							<h4
								onClick={() => {
									unCheck();
								}}
							>
								HOME
							</h4>
						</Link>
						<Link to='/game'>
							<h4
								onClick={() => {
									unCheck();
								}}
							>
								GAME
							</h4>
						</Link>
						<Link to='/masterlist'>
							<h4
								onClick={() => {
									unCheck();
								}}
							>
								MASTERLIST
							</h4>
						</Link>
						<Link to='/marketplace'>
							<h4
								onClick={() => {
									unCheck();
								}}
							>
								MARKETPLACE
							</h4>
						</Link>
						{role === 'admin' && (
							<Link to='/admin/secretadminstuff'>
								<h4
									onClick={() => {
										unCheck();
									}}
								>
									ADMIN
								</h4>
							</Link>
						)}
					</div>
				</label>
				<div className='nav__buttons'>
					{/* <div class='dropdown'>
					<button class='dropbtn'>Dropdown</button>
					<div class='dropdown-content'>
						<a href='#'>Link 1</a>
						<a href='#'>Link 2</a>
						<a href='#'>Link 3</a>
					</div>
				</div> */}
					<h3 className='nav__page'>{page}</h3>
					{isAuth ? (
						<Fragment>
							<div className='nav__page--user'>
								<Link to='/profile'>
									<p className='nav__page--user-name'>{user}</p>
								</Link>
								<div className='nav-dropbtn'>▼</div>
								<div className='nav-dropcontent'>
									<Link to='/dash'>
										<p className='nav__auth'>dashboard</p>
									</Link>
									<Link to='/account'>
										<p className='nav__auth'>account</p>
									</Link>
									<p
										className='nav__auth'
										onClick={(e) => {
											logout(e);
										}}
									>
										logout
									</p>
								</div>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<Link to='/login'>
								<p className='nav__auth'>login</p>
							</Link>
							<span className='nav-spacer'>•</span>
							<Link to='/signup'>
								<p className='nav__auth'>sign up</p>
							</Link>
						</Fragment>
					)}
				</div>
				<ul>
					{tabs &&
						tabs.map((tab) => (
							<li className='nav-tab' onClick={(e) => clicky(tab)}>
								{tab}
							</li>
						))}
				</ul>
				{/* {!isVerified && (
					<div className='not-verified'>
						Click here to verify your account!
					</div>
				)} */}
			</nav>
		</Fragment>
	);
};

export default Nav;
