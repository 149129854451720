/** @format */

import React, { useEffect, Fragment } from 'react';
import axios from 'axios';

const Logout = ({ history, setPage, setIsAuth, setUser}) => {

    useEffect(() => {
        logout()
	}, []);

    const logout = () => {
		console.log('logout');
		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/logout`)
			: (url = `${process.env.REACT_APP_API}/logout`);
		axios({
			method: 'POST',
			url,
			withCredentials: true,
		})
			.then((res) => {
                console.log(res);
                setIsAuth(false)

			})
            .catch((error) => {
                console.log('error')
				console.log(error);
			});
    }

	return (
		<Fragment></Fragment>
	);
};

export default Logout;
