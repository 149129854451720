/** @format */

import React, { useState, Fragment, useEffect } from 'react';
import axios from 'axios';

import { DragDropContext } from 'react-beautiful-dnd';
import { reorderColors } from './reorder';

import { getColors, getRandomColor } from '../../../utils/colors';

import { ColorList } from './ColorList';
import PetCanvas from '../../../components/PetCanvas/PetCanvas';
import EditColor from './EditColor';

import pomLine from '../../../img/pets/pom/line.png';
import pomBase from '../../../img/pets/pom/base.png';
import pomBaseShade from '../../../img/pets/pom/baseShade.png';
import pomBaseInner from '../../../img/pets/pom/baseLine.png';
import pomMarking from '../../../img/pets/pom/marking.png'
import pomMarkingShade from '../../../img/pets/pom/markingShade.png';
import pomMarkingLine from '../../../img/pets/pom/markingLine.png'

const ColorTool = ({ setPage, setTabs }) => {
	const [colorMap, setColorMap] = useState({
		a: null,
	});
	const [selectedColor, setSelectedColor] = useState(null);
	const [comparisonColors, setComparisonColors] = useState(null);
	const [markingColor, setMarkingColor] = useState(null);
	const [editColor, setEditColor] = useState(null);
	const [petBaseObj, setPetBaseObj] = useState({
		line: pomLine,
		base: pomBase,
		baseShade: pomBaseShade,
		baseLine: pomBaseInner,
	});
	const [editWindow, setEditWindow] = useState(false);

	useEffect(() => {
		if (colorMap.a === null) {
			getColorState();
		}
	}, [selectedColor]);

	const getColorState = async () => {
		const colorsArray = await getColors();
		setColorMap({
			a: colorsArray,
		});
		//
		const baseColor = colorsArray[0];
		const markingColor = getRandomColor(colorsArray);
		//
		setSelectedColor(baseColor);
		setEditColor(baseColor);
		setMarkingColor(markingColor.color);

		getAdjacentColors(colorsArray, 0);
	};

	const randomiseMarkingColor = async () => {
		const colorsArray = await getColors();
		const newColor = getRandomColor(colorsArray);
		setMarkingColor(newColor.color);
	}

	const submitEditedColor = (e, type) => {
		e.preventDefault();
		let url;

		if (type === 'edit') {
			process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/color/${editColor._id}`)
			: (url = `${process.env.REACT_APP_API}/color/${editColor._id}`);
		} else if (type === 'post') {
			process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/color`)
			: (url = `${process.env.REACT_APP_API}/color`);
		}
		
		axios({
			method: type === 'edit' ? 'PATCH' : 'POST',
			url,
			data: editColor,
			withCredentials: true,
		})
			.then((res) => {
				console.log(res);
				getColors();
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getDragColor = (source, destination) => {
		const index = destination.index;

		let num1 = index,
			num2 = index,
			max = colorMap.a.length;
		if (index < source.index) {
			num1--;
		} else if (index > source.index) {
			num2++;
		} else {
			num1--;
			num2++;
		}
		//
		if (num1 <= 0) {
			num1 = 0;
		}
		if (num2 === max) {
			num2 = max - 1;
		}
		//
		setComparisonColors({
			color1: colorMap.a[num1],
			color2: colorMap.a[num2],
		});
		//
		if (!destination) {
			setSelectedColor(colorMap.a[source.index]);
		} else {
			setSelectedColor(colorMap.a[source.index]);
		}
	};

	const getAdjacentColors = (colorsArray, index) => {
		let num1 = index - 1;
		let num2 = index + 1;
		//
		if (num1 <= -1) {
			num1 = 0;
		}
		if (num2 === colorsArray.length) {
			num2 = num2 - 1;
		}
		//
		setComparisonColors({
			color1: colorsArray[num1],
			color2: colorsArray[num2],
		});
	};

	const submitColorOrder = (e) => {
		e.preventDefault();
		let newMap = colorMap.a.map((col, index) => {
			const newItem = col;
			newItem.order = index;
			return newItem;
		});

		let url;
		const data = newMap
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/colors`)
			: (url = `${process.env.REACT_APP_API}/colors`);
		axios({
			method: 'PATCH',
			url,
			data,
			withCredentials: true,
		})
			.then((res) => {
				console.log(res);
				alert('success! added color');
				getColors();
			})
			.catch((error) => {
				console.log(error);
				alert('color submission failed.');
			});
	};

	const createPalette = (color) => {
		return (
			<Fragment>
				<div style={{ backgroundColor: color.base }}>base</div>
				<div style={{ backgroundColor: color.shade }}>shade</div>
				<div style={{ backgroundColor: color.line }}>line</div>
			</Fragment>
		);
	};

	return (
		<div className='color-tool'>
			{editWindow === true && (
				<EditColor
					editColor={editColor}
					setEditColor={setEditColor}
					markingColor={markingColor}
					petBaseObj={petBaseObj}
					selectedColor={selectedColor}
					setEditWindow={setEditWindow}
					submitColor={submitEditedColor}
					pet={{
						line: pomLine,
						base: pomBase,
						baseShade: pomBaseShade,
						baseLine: pomBaseInner,
						marking: pomMarking,
						markingShade: pomMarkingShade,
						markingLine: pomMarkingLine,
					}}
				/>
			)}

			<div className='colors'>
				{colorMap.a && (
					<DragDropContext
						onDragEnd={({ destination, source }) => {
							if (!destination) {
								return;
							}

							setColorMap(reorderColors(colorMap, source, destination));
							getDragColor(source, destination);
						}}
					>
						{Object.entries(colorMap).map(([k, v]) => (
							<ColorList
								internalScroll={true}
								key={k}
								listId={k}
								listType='CARD'
								colors={v}
								setSelectedColor={setSelectedColor}
								colorMap={colorMap.a}
								setEditColor={setEditColor}
								getAdjacentColors={getAdjacentColors}
							/>
						))}
					</DragDropContext>
				)}
			</div>

			{selectedColor && (
				<div className='color-tool__name'>
					<h3 style={{ color: selectedColor.base }}>{selectedColor.name}</h3>
					<button onClick={() => setEditWindow(true)}>edit color</button>
				</div>
			)}
			{comparisonColors && (
				<div className='color-tool__comparison'>
					{createPalette(comparisonColors.color1)}
					{createPalette(selectedColor)}
					{createPalette(comparisonColors.color2)}
				</div>
			)}

			<div className='color-tool__grid'>
				{colorMap.a &&
					colorMap.a.map((col) => (
						<div
							className='grid-swatch'
							key={col.name}
							style={{
								backgroundColor: col.base,
								color: col.shade,
								borderColor: col.line,
								borderStyle: 'solid',
								borderWidth: '3px',
							}}
							onClick={() => {
								setSelectedColor(col);
								setEditColor(col);
								randomiseMarkingColor();
								getAdjacentColors(colorMap.a, col.order)
							}}
						>
							•
						</div>
					))}
			</div>

			<div className='color-tool__selected'>
				{selectedColor && markingColor && (
					<Fragment>
						{petBaseObj !== null && selectedColor && (
							<PetCanvas
								markings={true}
								marking={markingColor}
								color={selectedColor}
								pet={{
									line: pomLine,
									base: pomBase,
									baseShade: pomBaseShade,
									baseLine: pomBaseInner,
									marking: pomMarking,
									markingShade: pomMarkingShade,
									markingLine: pomMarkingLine,
								}}
							/>
						)}
						<div className='color-tool__selected--blocks'>
							{createPalette(selectedColor)}
						</div>
					</Fragment>
				)}
			</div>
			<button className='color-tool__btn' onClick={(e) => submitColorOrder(e)}>
				SUBMIT NEW COLOR ORDER
			</button>
		</div>
	);
};

export default ColorTool;
