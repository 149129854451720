/** @format */

import React from 'react';
import headshot from '../../img/headshot.png';
import crow from '../../img/crow.png';
import Gallery from './Gallery.jsx';

const Summary = ({ gallery, user}) => {
	return (
		<div className='summary'>
			<div className='summary__content'>
				<div className='summary__content--header'>
					<img
						src='https://a.deviantart.net/avatars-big/s/a/sadbloom.gif?13'
						alt=''
					/>
					<div className='user-details'>
						<div className='username'>
							<h4>{user.username.toUpperCase()}</h4>
						</div>
						<p className='level'>LV 10</p>
						<p className='joined'>joined 11/20</p>
					</div>
				</div>
				<div className='summary__content--activity'>
					<div className='log-entry'>
						{' '}
						<p className='date'>12/20/21</p>{' '}
						<p className='log-entry__data'>
							<span className='log-entry__data--character'>Character</span>
							searched{' '}
							<span className='log-entry__data--area'> Area </span>
							and found a(n) <span className='log-entry__data--common'>item</span>
						</p>{' '}
					</div>
				</div>
				<div className='summary__content--tasks'>
					<div className='task task-active'>
						<img className='task-character' src={headshot} alt='chara icon' />
						<div className='task-area'>
							{' '}
							<p className='character-name'>
								<i class='fas fa-caret-right'></i> Hesina
							</p>
							<p className='familiar-name'>
								Crow <i class='fas fa-caret-left'></i>
							</p>
						</div>
						<img className='task-familiar' src={crow} alt='chara icon' />
						<p className='task-description'>
							<div>
								<span className='task-type'>searching:</span>
								<span className='task-location'>the Wilds</span>
							</div>
							<p className='task-timer'>00:00:00</p>
						</p>
					</div>
					<div className='task task-active'>
						<img className='task-character' src={headshot} alt='chara icon' />
						<div className='task-area'>
							{' '}
							<p className='character-name'>
								<i class='fas fa-caret-right'></i> Hesina
							</p>
							<p className='familiar-name'>
								Crow <i class='fas fa-caret-left'></i>
							</p>
						</div>
						<img className='task-familiar' src={crow} alt='chara icon' />
						<p className='task-description'>
							<div>
								<span className='task-type'>searching:</span>
								<span className='task-location'>the Wilds</span>
							</div>
							<p className='task-timer'>00:00:00</p>
						</p>
					</div>
					<div className='task-locked'>
						<i class='fas fa-unlock task-lock-icon'></i>
						<p className='task-description'>
							<div>NO CHARACTER ASSIGNED</div>
							<div>--</div>
						</p>
					</div>
					<div className='task-locked'>
						<i class='fas fa-lock task-lock-icon'></i>
						<p className='task-description'>
							<div>TASK SLOT LOCKED</div>
							<div>unlocks: player LV10</div>
						</p>
					</div>
				</div>

				<div className='summary__content--art'>
					<h4>Recent Artwork</h4>
					{gallery && <Gallery gallery={gallery} size={200}/>}
				</div>
			</div>

			<div className='summary__social'>
				<div className='title'>COMMENTS</div>
				<div className='comments'>
					{' '}
					<div className='comment'>
						<img
							className='comment-icon'
							src='https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/c897cd74-395c-437b-9db8-6b7257d13718/devd6tu-bc27b6ae-c1f4-40dc-a190-c79c8c745c36.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2M4OTdjZDc0LTM5NWMtNDM3Yi05ZGI4LTZiNzI1N2QxMzcxOFwvZGV2ZDZ0dS1iYzI3YjZhZS1jMWY0LTQwZGMtYTE5MC1jNzljOGM3NDVjMzYucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.DZRhIJAYG-U7Ns8PnhWsJhgTuXO7iwjmr0WaEltwRpU'
							alt='user icon'
						></img>
						<div className='comment-body'>
							woahhh bloom you are so cool thanks for being alive
						</div>
						<div className='comment-user hidden'>bloom</div>
					</div>
					<div className='comment'>
						<img
							className='comment-icon'
							src='https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/c897cd74-395c-437b-9db8-6b7257d13718/devd6tu-bc27b6ae-c1f4-40dc-a190-c79c8c745c36.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2M4OTdjZDc0LTM5NWMtNDM3Yi05ZGI4LTZiNzI1N2QxMzcxOFwvZGV2ZDZ0dS1iYzI3YjZhZS1jMWY0LTQwZGMtYTE5MC1jNzljOGM3NDVjMzYucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.DZRhIJAYG-U7Ns8PnhWsJhgTuXO7iwjmr0WaEltwRpU'
							alt='user icon'
						></img>
						<div className='comment-body'>
							woahhh bloom you are so cool thanks for being alive
						</div>
						<div className='comment-user hidden'>bloom</div>
					</div>
					<div className='comment'>
						<img
							className='comment-icon'
							src='https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/c897cd74-395c-437b-9db8-6b7257d13718/devd6tu-bc27b6ae-c1f4-40dc-a190-c79c8c745c36.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2M4OTdjZDc0LTM5NWMtNDM3Yi05ZGI4LTZiNzI1N2QxMzcxOFwvZGV2ZDZ0dS1iYzI3YjZhZS1jMWY0LTQwZGMtYTE5MC1jNzljOGM3NDVjMzYucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.DZRhIJAYG-U7Ns8PnhWsJhgTuXO7iwjmr0WaEltwRpU'
							alt='user icon'
						></img>
						<div className='comment-body'>
							woahhh bloom you are so cool thanks for being alive
						</div>
						<div className='comment-user hidden'>banan</div>
					</div>
				</div>

				<div className='add-comment'>add comment</div>
			</div>
		</div>
	);
};

export default Summary;
