/** @format */

import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';

import thumb from '../../img/headshot.png';
import icon from '../../img/usericon.gif';

const Connect = ({ setPage, setIsAuth, setTabs }) => {
	const [connectionMessage, setConnectionMessage] = useState(null);

	useEffect(() => {
		getConnection();
		setPage('HOME');
		setTabs([]);
	}, []);

	const getConnection = () => {
		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/`)
			: (url = process.env.REACT_APP_API);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				setConnectionMessage(res.data.message);
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
			});
	};

	return (
		<div className='home'>
			{/*
			<div className='home__featured'>
				<div className='home__featured--character'>
					<h5 className='home__featured--character__title'>
						FEATURED CHARACTER
					</h5>
					<div className='home__featured--character__box'>
						<div className='home__featured--character__box--img'>
							<img
								className='home__featured--character__box--img-thumbnail'
								src={thumb}
								alt=''
							/>
							<div className='home__featured--character__box--img-bg'> </div>
						</div>
						<h5 className='home__featured--character__box--name'>charaname</h5>
					</div>
					<div className='home__featured--character__box--owner'>
						<img
							src={icon}
							alt=''
							className='home__featured--character__box--owner-icon'
						/>
						<h5 className='home__featured--character__box--owner-name'>
							ownername
						</h5>
					</div>
					<div className='home__featured--character__bg'> </div>
					{/* <h3 className="owner">banan</h3> *
				</div>
				<div className='home__featured--work'>
					<h5 className='home__featured--work__title'>FEATURED WORK</h5>
					<div className='home__featured--work__box'>
						<img
							className='home__featured--work__box--img'
							src='https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/ef6c6f79-f485-4075-a862-0555f1fab142/debwhol-ce80a015-7ab4-4c92-a0d2-4cb8e578f99c.png/v1/fill/w_1280,h_792,q_80,strp/blood_moon_rising_by_sadbloom_debwhol-fullview.jpg?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7ImhlaWdodCI6Ijw9NzkyIiwicGF0aCI6IlwvZlwvZWY2YzZmNzktZjQ4NS00MDc1LWE4NjItMDU1NWYxZmFiMTQyXC9kZWJ3aG9sLWNlODBhMDE1LTdhYjQtNGM5Mi1hMGQyLTRjYjhlNTc4Zjk5Yy5wbmciLCJ3aWR0aCI6Ijw9MTI4MCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.bbFHmdeu8EwP6Oxd_yO7fwLiCXtW_1tDlPXAIhBBnlQ'
							alt=''
						/>
					</div>
					<div className='home__featured--work__box--owner'>
						<img
							src={icon}
							alt=''
							className='home__featured--work__box--owner-icon'
						/>
						<h5 className='home__featured--work__box--owner-name'>ownername</h5>
					</div>
					{/* <h3 className="owner">banan</h3> 
				</div>
				<div className='home__featured--new'>new</div>
			</div>
		*/}
			<h1 className='home__title'>
				<h1>Hanging Lights</h1>
			</h1>
			<div className='home__banner'> </div>
			<div className='home__border'> </div>
			<div className='home__news'>
				<div className='home__news--post'>
					<h3 className='title'>Welcome to HL!</h3>
					<p className=''>
						All features are currently in a test phase, and not open to the
						public.
						<br></br>
						Many pages required a login- but feel free to browse around and see
						what i'm working on!
						<br></br>
						<br></br>
						This page is unfinished and being used as a static template.
						<br />
						Many pages are not yet styled for mobile. This website is best
						viewed on desktop.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Connect;
