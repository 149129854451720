/** @format */

import React, { useState, useEffect, Fragment } from 'react';
import ItemDetails from './ItemDetails';

import axios from 'axios';

import ColorGrid from '../../../components/ColorGrid';

import blank from '../../../img/pets/pom1/blank.png';

const Database = () => {
	const [colorMap, setColors] = useState(null);
	const [selectedColor, setSelectedColor] = useState(null);
	const [markingColor, setMarkingColor] = useState(null);
	const [file, setFile] = useState({
		line: null,
		base: null,
		baseShade: null,
		baseLine: null,
	});
	const [img, setImg] = useState('');
	const [petObj, setPetObj] = useState({ name: null, rarity: 'common' });
	const [petBaseObj, setPetBaseObj] = useState({
		line: blank,
		base: blank,
		baseShade: blank,
		baseLine: blank,
	});
	const [select, setSelect] = useState('CREATE');
	const [selectSecondary, setSelectSecondary] = useState('COLORS');
	const [selectMarkings, setSelectMarkings] = useState('SHIBE');
	const [details, setDetails] = useState('image');
	const [colorSelect, setColorSelect] = useState('common');
	const [commonColors, setCommonColors] = useState([
		'vanilla',
		'peach',
		'dandelion',
		'sky',
		'lavender',
	]);
	const [rareColors, setRareColors] = useState(['space', 'jam']);
	const [mutationColors, setMutationColors] = useState([]);
	const handleChange = (field, value, setTarget) => {
		setTarget((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	useEffect(() => {
		if (!colorMap) getColors();
	}, [selectedColor]);

	const getColors = () => {
		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/colors`)
			: (url = `${process.env.REACT_APP_API}/colors`);

		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				console.log(res);
				let colorsArray = res.data.data;
				setColors(
					colorsArray.sort((a, b) => {
						return a.order - b.order;
					})
				);
				setSelectedColor(colorsArray[0]);
				setMarkingColor(
					colorsArray[Math.floor(Math.random() * colorsArray.length)]
				);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const pTab = (setTarget, variable, value) => {
		return (
			<p
				className={variable === value ? 'active' : ''}
				onClick={() => {
					setTarget(value);
				}}
			>
				{value}
			</p>
		);
	};

	const submitImg = (e, target) => {
		let img = new Image();
		img.src = window.URL.createObjectURL(e[0]);

		const ext = e[0].name.match(/\.([^\.]+)$/)[1];

		switch (ext) {
			case 'png':
				setFile((prev) => ({
					...prev,
					[target]: e[0],
				}));
				setPetBaseObj((prev) => ({
					...prev,
					[target]: URL.createObjectURL(e[0]),
				}));
				break;
			default:
				e = '';
				alert('Only the following file types are allowed: .png, .jpg, .jpeg');
				setFile(null);
		}
	};

	const submitPet = () => {
		let data = new FormData();

		const appendArray = ['name', 'rarity', 'commonColors', 'rareColors'];
		appendArray.forEach((el) => {
			data.append(el, petObj[el]);
		});

		data.append('baseImages', file.line, `${petObj.name}-line`);
		data.append('baseImages', file.base, `${petObj.name}-base`);
		data.append('baseImages', file.baseShade, `${petObj.name}-baseShade`);
		data.append('baseImages', file.baseLine, `${petObj.name}-baseLine`);

		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/pets`)
			: (url = `${process.env.REACT_APP_API}/pets`);

		axios({
			method: 'POST',
			url,
			withCredentials: true,
			data,
		})
			.then((res) => {
				console.log(res);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<div className='admin__data'>
			<div className='admin__data--nav'>
				<div className='admin__master--nav-primary'>
					<h3>
						DATABASE <span>•</span>
					</h3>

					{pTab(setSelect, select, 'PETS')}
					{pTab(setSelect, select, 'FAMILIARS')}
					{pTab(setSelect, select, 'ITEMS')}
					{pTab(setSelect, select, 'TRAITS')}
				</div>
			</div>
			{petBaseObj && (
				<ItemDetails
					marking={markingColor}
					colors={colorMap}
					base={selectedColor}
					pet={petBaseObj}
					petObj={petObj}
					setPetObj={setPetObj}
					submitPet={submitPet}
				/>
			)}

			<div className='admin__data--secondary'>
				<div className='admin__data--secondary-options'>
					<h3
						className={selectSecondary === 'COLORS' ? 'active' : ''}
						onClick={() => {
							setSelectSecondary('COLORS');
						}}
					>
						COLORS
					</h3>
					<h3
						className={selectSecondary === 'BASE' ? 'active' : ''}
						onClick={() => {
							setSelectSecondary('BASE');
						}}
					>
						BASE
					</h3>
					<h3
						className={selectSecondary === 'MARKINGS' ? 'active' : ''}
						onClick={() => {
							setSelectSecondary('MARKINGS');
						}}
					>
						MARKINGS
					</h3>
					<h3
						className={selectSecondary === 'LOCATIONS' ? 'active' : ''}
						onClick={() => {
							setSelectSecondary('LOCATIONS');
						}}
					>
						LOCATIONS
					</h3>
				</div>
				{selectSecondary === 'BASE' && (
					<div className='admin__data--secondary-base'>
						<div className='admin__data--pet-img-group'>
							<div className='admin__data--pet-img-group-src'>
								<h3>LINE</h3>
								<input
									type='file'
									accept='.png'
									onChange={(e) => submitImg(e.target.files, 'line')}
								/>
								{petBaseObj && petBaseObj.line && (
									<img src={petBaseObj.line} alt='' />
								)}
							</div>
							<div className='admin__data--pet-img-group-src'>
								<h3>BASE</h3>
								<input
									type='file'
									accept='.png'
									onChange={(e) => submitImg(e.target.files, 'base')}
								/>
								{petBaseObj && petBaseObj.base && (
									<img src={petBaseObj.base} alt='' />
								)}
							</div>
							<div className='admin__data--pet-img-group-src'>
								<h3>BASE SHADE</h3>
								<input
									type='file'
									accept='.png'
									onChange={(e) => submitImg(e.target.files, 'baseShade')}
								/>
								{petBaseObj && petBaseObj.baseShade && (
									<img src={petBaseObj.baseShade} alt='' />
								)}
							</div>
							<div className='admin__data--pet-img-group-src'>
								<h3>BASE INNER LINE</h3>
								<input
									type='file'
									accept='.png'
									onChange={(e) => submitImg(e.target.files, 'baseLine')}
								/>
								{petBaseObj && petBaseObj.baseLine && (
									<img src={petBaseObj.baseLine} alt='' />
								)}
							</div>
						</div>
					</div>
				)}
				{selectSecondary === 'COLORS' && (
					<div className='admin__data--secondary-color'>
						{colorMap && (
							<Fragment>
								<div className='admin__data--secondary-color-group'>
									<h5>COMMON</h5>
									<ColorGrid
										colors={colorMap}
										filterColors={true}
										rarity={commonColors}
										setRarity={setCommonColors}
										filter={rareColors}
										setFilter={setRareColors}
									/>
								</div>
								<div className='admin__data--secondary-color-group'>
									<h5>RARE</h5>
									<ColorGrid
										colors={colorMap}
										filterColors={true}
										rarity={rareColors}
										setRarity={setRareColors}
										filter={commonColors}
										setFilter={setCommonColors}
									/>
								</div>
								<div className='admin__data--secondary-color-group'>
									<h5>SHINY</h5>
									<ColorGrid
										colors={colorMap}
										filterColors={true}
										mutation={true}
										filter={commonColors.concat(rareColors)}
									/>
								</div>
							</Fragment>
						)}
					</div>
				)}
				{selectSecondary === 'MARKINGS' && (
					<div className='admin__data--secondary-markings'>
						<div className='admin__data--pet-img-group'>
							<div
								className={selectMarkings === 'SHIBE' ? 'active' : ''}
								onClick={() => {
									setSelectMarkings('SHIBE');
								}}
							>
								SHIBE
							</div>
							<div
								className={selectMarkings === 'BUBBLES' ? 'active' : ''}
								onClick={() => {
									setSelectMarkings('BUBBLES');
								}}
							>
								BUBBLES
							</div>
							<div
								className={selectMarkings === 'VALENTINE' ? 'active' : ''}
								onClick={() => {
									setSelectMarkings('VALENTINE');
								}}
							>
								VALENTINE
							</div>
							<div
								className={selectMarkings === 'MASK' ? 'active' : ''}
								onClick={() => {
									setSelectMarkings('MASK');
								}}
							>
								MASK
							</div>
						</div>
						<div className='admin__data--secondary-markings-marking'>
							<h3 className='admin__data--secondary-markings-marking-name'>
								SHIBE
							</h3>
							<div className='admin__data--secondary-markings-marking-images'>
								{/* <div className='marking-image-group'>
									<input
										type='file'
										accept='.png'
										onChange={(e) => submitImage(e.target.files)}
									/>
									img src=''
								</div> */}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Database;
