/** @format */

import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toast.css';
import Nav from './layout/Nav';
import Gallery from './pages/Gallery';
import Home from './pages/home/Home';
import Masterlist from './pages/masterlist/Masterlist';
import Claim from './pages/masterlist/Claim';
import Traits from './pages/Traits';
import Garden from './pages/Garden';
import Game from './pages/Game';
import Database from './pages/Database';
import Marketplace from './pages/Marketplace';
import Profile from './pages/profile/Profile';
import Area from './pages/Explore';
import PetCustom from './pages/PetCustom';

import DAcode from './pages/account/DAcode';

import Map from './pages/map/Map.jsx';

import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';
import Logout from './pages/auth/Logout';
import Verify from './pages/auth/Verify';

import Dash from './pages/account/Dash';

import ProtectedRoute from './pages/auth/ProtectedRoute';
import AdminRoute from './pages/auth/AdminRoute';
import Admin from './pages/admin/Admin';
import PetCanvasTest from './pages/test/PetCanvasTest';

const Router = () => {
	const [isAuth, setIsAuth] = useState(false);
	const [user, setUser] = useState(false);
	const [page, setPage] = useState();
	const [target, setTarget] = useState('home');
	const [tabs, setTabs] = useState([]);
	const [traitsData, setTraitsData] = useState();

	useEffect(() => {
		if (window.location.href.includes('hlights-test')) {
			window.location.assign(
				`https://www.hlights.net${window.location.pathname}`
			);
		}
	}, []);

	const propsDefault = {
		setPage: setPage,
		setTabs: setTabs,
		setIsAuth: setIsAuth,
		setUser,
		page: page,
		tabs: tabs,
		isAuth: isAuth,
		user: user,
	};

	return (
		<Fragment>
			<ToastContainer />
			<BrowserRouter>
				<Nav
					{...propsDefault}
					setTarget={setTarget}
					setIsAuth={setIsAuth}
					setUser={setUser}
				/>
				<Routes>
					<Route
						exact
						path='/'
						element={
							<Home {...propsDefault}/>
						}
					/>
					<Route
						path='/gallery/:user'
						render={(props) => <Gallery {...props} {...propsDefault} />}
					/>
					<Route
						path='/map'
						render={(props) => <Map {...props} {...propsDefault} />}
					/>
					<Route
						exact
						path="/masterlist"
						element={<Masterlist
							setPage={setPage}
							setTarget={setTarget}
							setUser={setUser}
							setTabs={setTabs} />} />
					<Route
						path='/masterlist/claim'
						element={<Claim
							setPage={setPage}
							setTarget={setTarget}
							setUser={setUser}
							setTabs={setTabs} />}
					/>
					<Route
						path='/traits'
						render={(props) => (
							<Traits
								{...props}
								{...propsDefault}
								setTraitsData={setTraitsData}
							/>
						)}
					/>
					<Route
						path='/verify'
						render={(props) => <Verify {...props} {...propsDefault} />}
					/>
					<Route
						path='/profile'
						render={(props) => <Profile {...props} {...propsDefault} />}
					/>
					<Route path='/garden' component={Garden} {...propsDefault} />
					<Route
						path='/game'
						render={(props) => <Game {...props} {...propsDefault} />}
					/>
					<Route
						path='/database'
						render={(props) => <Database {...props} {...propsDefault} />}
					/>
					<Route
						path='/area'
						render={(props) => <Area {...props} {...propsDefault} />}
					/>
					<Route
						path='/pet'
						render={(props) => <PetCustom {...props} {...propsDefault} />}
					/>
					<Route
						path='/marketplace'
						render={(props) => <Marketplace {...props} {...propsDefault} />}
					/>
					/>
					{/*  */}
					{/* AUTH */}
					{/*  */}
					<Route
						path='/login'
						element={<Login
							setPage={setPage}
							setTarget={setTarget}
							setUser={setUser}
							setTabs={setTabs}
							setIsAuth={setIsAuth} />}
					/>
					<Route
						path='/signup'
						render={(props) => <Signup {...props} {...propsDefault} />}
					/>
					<Route
						path='/logout'
						element={<Logout
							setPage={setPage}
							setTarget={setTarget}
							setUser={setUser}
							setTabs={setTabs}
							setIsAuth={setIsAuth} />}
					/>
					<Route
						path='/testpet'
						render={(props) => <PetCanvasTest {...props} {...propsDefault} />}
					/>
					<Route
						path='/dacode'
						render={(props) => <DAcode {...props} {...propsDefault} />}
					/>
					{/*  */}
					{/* ADMIN */}
					{/*  */}
					<Route
						path='/admin/secretadminstuff'
						element={
							<AdminRoute>
								<Admin {...propsDefault}
								target={target}
								setTarget={setTarget}/>
							</AdminRoute>
						}
					/>
					<Route
						path='/admin/protect'
						element={
							<ProtectedRoute>
								<Home {...propsDefault}
								target={target}
								setTarget={setTarget}/>
							</ProtectedRoute>
						}
					/>
					<Route
						path='/dash'
						element={
							<ProtectedRoute>
								<Dash {...propsDefault}
								target={target}
								setTarget={setTarget}/>
							</ProtectedRoute>
						}
					/>
				</Routes>
			</BrowserRouter>
		</Fragment>
	);
};

export default Router;
