/** @format */

import React, { useState, useEffect, Fragment } from 'react';
import Masterlist from './masterlist/Masterlist';
import Database from './database/Database';
import Color from './color/ColorTool'

const Admin = ({ target, tabs, setTabs, setTarget, setPage }) => {
	useEffect(() => {
		setPage('ADMIN');
		setTabs(['MASTERLIST', 'USERS', 'DATABASE', 'COLOR']);
		setTarget('MASTERLIST');
	}, []);
	return (
		<Fragment>
			<div className='admin'>
				{target === 'HOME' && (
					<div className='admin__tab'>
						<h3>HOME</h3>
					</div>
				)}
				{target === 'MASTERLIST' && <Masterlist />}
				{target === 'USERS' && (
					<div className='admin__tab'>
						<h3>USERS</h3>
					</div>
				)}
				{target === 'DATABASE' && <Database />}
				{target === 'COLOR' && <Color />}
			</div>
		</Fragment>
	);
};

export default Admin;
