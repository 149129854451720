/** @format */

import React, { useState, useEffect, Fragment } from 'react';
import PetCanvas from '../../components/PetCanvas/PetCanvasTest';
import axios from 'axios';

import { getColors, getRandomColor} from '../../utils/colors';

//images
import pomLine from '../../img/pets/pom1/pom_line.png';
import pomBase from '../../img/pets/pom1/pomex_base.png';
import pomBaseShade from '../../img/pets/pom1/pom_base_shade.png';
import pomBaseInner from '../../img/pets/pom1/pom_base_inner.png';

const PetCanvasTest = ({ setPage, setTabs }) => {
	const [petBaseObj, setPetBaseObj] = useState(null);
	const [colors, setColors] = useState(null);
	const [selectedColor, setSelectedColor] = useState(null);

	useEffect(() => {
		setPage('PETCOLORTEST');
		setTabs([]);
		if (petBaseObj === null) {
			setPetBaseObj({
				line: pomLine,
				base: pomBase,
				baseShade: pomBaseShade,
				baseLine: pomBaseInner,
			});
		}
		if (colors === null) {
			getColorState();
		}
	}, [colors]);

	const getColorState = async () => {
		const colorsArray = await getColors();
		setColors(
			colorsArray.sort((a, b) => {
				return a.order - b.order;
			})
        );
        
        setSelectedColor(getRandomColor(colorsArray))
        console.log(selectedColor)
	};

	return (
		<div>
			<Fragment>
				<h3>Testing Pet Canvas</h3>
			</Fragment>
			{petBaseObj && selectedColor && (
				<PetCanvas markings={true} color={selectedColor} pet={petBaseObj} />
			)}
		</div>
	);
};

export default PetCanvasTest;
