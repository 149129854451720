/** @format */

import React, { useState, useEffect, Fragment, useCallback } from 'react';
import axios from 'axios';
import GalleryComponent from 'react-photo-gallery';
import SelectedImage from './SelectedImage';
import traits from '../../img/base.png';

import Rarity from '../../components/Rarity';

const MasterlistMini = ({ characters, setCharacters, setSelectedCharacters, setActiveSelect }) => {
	const [gallery, setGallery] = useState(null);
	const [filter, setFilter] = useState({
		species: 'weaver',
		subspecies: null,
		myo: false,
		rarity: 'all',
	});
	const [selectAll, setSelectAll] = useState(false);
	const toggleSelectAll = () => {
		setSelectAll(!selectAll);
	};

	useEffect(() => {
		getGallery();
		if (characters && gallery) {
			setSelectedCharacters(gallery.filter((el) => characters.indexOf(el.id) > -1))
		}
	}, [filter, characters]);

	const imageRenderer = useCallback(
		({ index, left, top, key, photo }) => (
			<SelectedImage
				onClick={(photo) => handleClick(photo)}
				selected={characters.includes(photo.id) ? true : false}
				key={key}
				margin={'2px'}
				index={index}
				photo={photo}
				left={left}
				top={top}
			/>
		),
		[selectAll, characters]
	);


	const createItems = (ml) => {
		ml.sort(function (a, b) {
			if (a.masterlist_id > b.masterlist_id) {
				return -1;
			}
		});
		setGallery(
			ml.map((el) => {
				return {
					height: el.masterlist_img.height,
					width: el.masterlist_img.width,
					src: el.masterlist_img.url,
					id: el._id,
				};
			})
		);
	};

	const getGallery = () => {
		console.log('fetching gallery...');
		let url;
		let query = '';
		query = query + 'species=' + filter.species;
		query = query + '&MYO=' + filter.myo;
		if (filter.subspecies) {
			query = query + '&subspecies=' + filter.subspecies;
		}
		if (filter.type) {
			query = query + '&type=' + filter.type;
		}
		if (filter.rarity !== 'all') {
			query = query + '&rarity=' + filter.rarity;
		}

		console.log(query);
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/character?${query}`)
			: (url = `${process.env.REACT_APP_API}/character?${query}`);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				createItems(res.data.data);
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
			});
	};

	const handleChange = (field, value) => {
		setFilter((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	const handleClick = (id) => {
		console.log(id)
		if (characters.indexOf(id) > -1) {
			console.log('includes')
			setCharacters(characters.filter(item => item !== id))
		} else {
			console.log('does not include')
			setCharacters(characters => [...characters, id])
		}
	}

	return (
		<div className='mini'>
			<div className='mini__filter'>
				<label className='mini__filter--select' for=''>
					<span>species</span>
					<select
						name=''
						id=''
						onChange={(e) => {
							handleChange('species', e.target.value);
						}}
					>
						<option value='weaver'>weaver</option>
						<option value='lumi'>lumi</option>
					</select>
				</label>
				<label className='mini__filter--select' for=''>
					<span>subspecies</span>
					<select
						name=''
						id=''
						onChange={(e) => {
							handleChange('subspecies', e.target.value);
						}}
					>
						<option value=''>all</option>
						<option value='silkweaver'>silkweaver</option>
						<option value='toxweaver'>toxweaver</option>
					</select>
				</label>
				<label className='mini__filter--select' for=''>
					<span>type</span>
					<select
						name=''
						id=''
						onChange={(e) => {
							handleChange('myo', e.target.value);
						}}
					>
						<option value={false}>official</option>
						<option value={true}>MYO</option>
					</select>
				</label>
				<label className='mini__filter--select' for=''>
					<span>rarity</span>
					<select
						name=''
						id=''
						onChange={(e) => {
							handleChange('rarity', e.target.value);
						}}
					>
						<option value={null}>all</option>
						<option value='unmutated'>unmutated</option>
						<option value='common'>common</option>
						<option value='uncommon'>uncommon</option>
						<option value='rare'>rare</option>
						<option value='super'>super</option>
						<option value='mythic'>mythic</option>
						<option value='unique'>unique</option>
					</select>
				</label>

				<div className='transfer-text'>available for:</div>

				<label className='mini__filter--check-tab check-tab' id='trade-check'>
					<input type='checkbox' />
					<p className='check-tab__text'>trade</p>
					<span className='checkmark'></span>
				</label>

				<label className='mini__filter--check-tab check-tab' id='sale-check'>
					<input type='checkbox' />
					<p className='check-tab__text'>sale</p>
					<span className='checkmark'></span>
				</label>
			</div>

			<div className='mini__component'>
				{gallery && (
					<div className='mini__view'>
						<div className='mini__view--images'>
							<GalleryComponent
								photos={gallery}
								targetRowHeight={200}
								renderImage={imageRenderer}
							/>
						</div>
					</div>
				)}
			</div>

			<div className="mini__finish">
				<div className="mini__finish--button" onClick={() => setActiveSelect(false)}>
					DONE SELECTING
				</div>
			</div>
		</div>
	);
};

export default MasterlistMini;
