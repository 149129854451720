/** @format */

import React, { useEffect, useState, Fragment } from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { checkAuth } from './helpers';

const AdminRoute = ({ children }) => {
	const [role, setRole] = useState(null);
	const [auth, setAuth] = useState(null);

	useEffect(() => {
		handleAuth();
	}, []);

	const handleAuth = async () => {
		const loggedIn = await checkAuth();
		console.log(loggedIn.role);
		setRole(loggedIn.role);
	};

	return (
		<Fragment>
			{role !== null && (
				<Fragment>
					{role === 'admin' ? children : <Navigate to='/' />}
				</Fragment>
			)}
		</Fragment>
	);
};

export default AdminRoute;
