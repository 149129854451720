/** @format */

import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const DAcode = () => {
	const [searchParams] = useSearchParams();
	console.log(searchParams); // ▶ URLSearchParams {}

	return <div>Users</div>;
};

export default DAcode;
