/** @format */

import React from 'react';

const CharacterImage = ({ img, clicky }) => {
	return (
		<div className='admin__master--character-image'>
			<div className='admin__master--character-image--file'>
				<input
					type='file'
					accept='.png,.jpg,.jpeg'
					onChange={(e) => clicky(e.target.files)}
				/>
			</div>
			<img src={img} alt='character' />
		</div>
	);
};

export default CharacterImage;
