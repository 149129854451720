/** @format */

import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';

import Account from './Account';

const Dash = ({ setPage, setTabs, isAuth
}) => {
	const [tab, setTab] = useState('account')
	const [user, setUser] = useState(null)
	useEffect(() => {
		setPage('DASHBOARD');
		getUser()
	}, []);
	const getUser = () => {
		let url;
		console.log('fetching gallery...');
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/user/name/banan`)
			: (url = `${process.env.REACT_APP_API}/user/name/banan`);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				console.log(res.data.data);
				setUser(res.data.data)
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
			});
	};
	return (
		<div className='dash'>
			{user && (
				<Fragment>
					<div className='dash__nav'>
				<i className='fas fa-comment-alt'></i>
				<div className='dash__nav--entry'>characters</div>
				<div className='dash__nav--entry'>tasks</div>
				<div className='dash__nav--entry'>requests</div>
				<div className='dash__nav--entry'>account </div>
			</div>
			{tab === 'account' && (
				<Account user={user} />
			)}
				</Fragment>
			)}
			
		</div>
	);
};

export default Dash;
