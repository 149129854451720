/** @format */

import { divide } from 'lodash';
import React, { useEffect, useState, Fragment } from 'react';

import mapbg from '../../img/mpbg.png';

const Map = ({ setPage, setTabs }) => {
	useEffect(() => {
		setPage('MAP');
		setTabs([]);
	}, []);
	return (
		<div className='map-container'>
			<div className='mapnav'>
				<div className='mapnav__box'> </div>
			</div>
			<div className='map'>
				{' '}
				<div className='map__spacer'> this is spacer </div>
				<img src='https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/df67me7-c25248a0-e0bf-4138-97cb-098a5b06fe82.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGY2N21lNy1jMjUyNDhhMC1lMGJmLTQxMzgtOTdjYi0wOThhNWIwNmZlODIucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.5JY9JJNhcoCknz0PXOR78ueLfOuG9sF3U3jHJ6SCpBo'></img>
			</div>
		</div>
	);
};

export default Map;
