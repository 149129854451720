
/** @format */

import React, { useState, Fragment } from 'react';
import Draggable from 'react-draggable';

import crow from '../../img/crow.png';
import characterImg from '../../img/profilecharacter.png';
import characterThumb from '../../img/profilecut.png';

const Characters = ({ match, setPage, setTabs, gallery, size }) => {
	const [character, setCharacter] = useState({
		name: 'Hesina',
		level: 12,
		activity: 'searching',
	});
	const [position, setPosition] = useState();

	const eventControl = (event, info) => {
		event.preventDefault();
		console.log('event name: ', event.type);

		if (event.type === 'mouseup') {
			console.log('ismouseup')
			setPosition(info);
		}
	};

	return (
		<Fragment>
			{gallery && (
				<div className='characters'>
					<div className='characters__character'>
						<Draggable
							onDrag={eventControl}
							onDragEnd={eventControl}
							onStart={eventControl}
							onStop={eventControl}
							onMouseDown={eventControl}
							onMouseUp={eventControl}
							onTouchStart={eventControl}
							onTouchEnd={eventControl}
						>
							<img
								className='characters__character--img'
								src={characterImg}
								alt=''
							/>
						</Draggable>
						<div className='characters__character--info'>
							<div className='characters__character--info-name'>charaname</div>
							<div className='characters__character--info-name'>LV 12</div>
						</div>
					</div>
					<div className='characters__character'>
						{position && (
							<img
								className='characters__character--img'
								src={characterImg}
								alt=''
								style={{
									transform: `translate(${position.lastX}px, ${position.lastY}px)`,
								}}
							/>
						)}

						<div className='characters__character--info'>
							<div className='characters__character--info-name'>charaname</div>
							<div className='characters__character--info-name'>LV 12</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default Characters;
