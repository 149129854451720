/** @format */

const colors = require('./colors.js');

const mothSilky = {
	name: 'Moth Silky',
	baseSrc:
		'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4mzg-d9935a86-f797-406a-8c84-03cfccab946d.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG16Zy1kOTkzNWE4Ni1mNzk3LTQwNmEtOGM4NC0wM2NmY2NhYjk0NmQucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.VBVgDQEVA-HSDkAojbPwrcOSyVdcVTJIEIgbBDCclhQ',
	lineSrc:
		'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4mzb-c1176f17-0a1d-4795-b5f1-3166a8f96578.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG16Yi1jMTE3NmYxNy0wYTFkLTQ3OTUtYjVmMS0zMTY2YThmOTY1NzgucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0._7PHZ1d6LHod2VRppUXiQwv2s7kYKXlok4K5uBTn_q4',
	shadeSrc:
		'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4mzc-e31ea725-64d7-453f-9642-4d204610de30.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG16Yy1lMzFlYTcyNS02NGQ3LTQ1M2YtOTY0Mi00ZDIwNDYxMGRlMzAucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.7EpNHD2G6VZ89pXTSNXNsyFCPasUVrROYRGvpNDV9HY',
	markings: {
		common: [
			{
				name: 'underbelly',
				layer: 'any',
				base: true,
				shade: true,
				baseSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4mxl-81e74185-40a6-43cf-91a0-183b9b6e58bc.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG14bC04MWU3NDE4NS00MGE2LTQzY2YtOTFhMC0xODNiOWI2ZTU4YmMucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.CwRqfyT9kwkuM0Q2Ne8Pe-rnYmKqqTqDR3lsCFHc0LU',
				shadeSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4mxa-41a3ddf9-9408-457c-88dd-4ee11b6274dd.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG14YS00MWEzZGRmOS05NDA4LTQ1N2MtODhkZC00ZWUxMWI2Mjc0ZGQucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.4wsXOojco-yehSDaSrHXq8mLde9TJgQDPJXGinggdT4',
			},
			{
				name: 'speckle',
				layer: 'any',
				base: true,
				shade: true,
				baseSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4myn-c4d2f361-db26-4590-8a92-fc00848212ee.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG15bi1jNGQyZjM2MS1kYjI2LTQ1OTAtOGE5Mi1mYzAwODQ4MjEyZWUucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.vPBOmxc1X4V2cl9DZBSy7wW1VZhEqnGc8AGraSu68rY',
				shadeSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4myh-1588d5cf-0ce2-4335-8a60-6b4556342767.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG15aC0xNTg4ZDVjZi0wY2UyLTQzMzUtOGE2MC02YjQ1NTYzNDI3NjcucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.WoLHaU7awOVAmYnfFkr_QrZyt4QsrTUtxR4DOy7g0Yk',
			},
			{
				name: 'tiger',
				layer: 'any',
				base: true,
				shade: true,
				baseSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4mxu-49e827d6-dedb-48a1-a830-ef16efa88c8a.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG14dS00OWU4MjdkNi1kZWRiLTQ4YTEtYTgzMC1lZjE2ZWZhODhjOGEucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.uZK0CqbFciMEmL-sIQ9bxrEUk0-_J_6smqpON5fLgu4',
				shadeSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4mxr-476af1d6-270c-478e-a547-40464232afa5.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG14ci00NzZhZjFkNi0yNzBjLTQ3OGUtYTU0Ny00MDQ2NDIzMmFmYTUucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.M4jD0cVeZdu3HYtPeGBwXLSCXZYR3fBvoCRo-3sr8_A',
			},
			{
				name: 'spotty',
				layer: 'any',
				base: true,
				shade: true,
				baseSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/desxei7-c31c6976-5cb1-441d-8245-edf6e4b8166b.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVzeGVpNy1jMzFjNjk3Ni01Y2IxLTQ0MWQtODI0NS1lZGY2ZTRiODE2NmIucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.H0ptl0y1PqKwVbHaW26nu4zYIV7-dkcsXA-C-U8L0Uc',
				shadeSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4my0-0dc09b29-03b4-45b2-8cfc-29c402beb183.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG15MC0wZGMwOWIyOS0wM2I0LTQ1YjItOGNmYy0yOWM0MDJiZWIxODMucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.WSMsQbWU6_7C5Do7uIw7KWCxLVsc0Qs-DMk47aJtBRk',
			},
		],
		rare: [
			{
				name: 'marble',
				layer: 'any',
				base: true,
				shade: true,
				baseSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4sf6-60c8a8fd-bbfb-4809-917f-f1041fb92c89.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNHNmNi02MGM4YThmZC1iYmZiLTQ4MDktOTE3Zi1mMTA0MWZiOTJjODkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.BbX9e-waX7RfWomOLGVzwpD_uvbUd-_FR7iePhffCSE',
				shadeSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4mz5-c07ebe9b-7dca-4240-9649-26e99486a7c9.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG16NS1jMDdlYmU5Yi03ZGNhLTQyNDAtOTY0OS0yNmU5OTQ4NmE3YzkucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.27STd2YL0Qrhhfz62PXnb_Mv5zTJoC5zJvD9OLucR0M',
			},
			{
				name: 'fade',
				layer: 'bottom',
				base: false,
				shade: true,
				baseSrc: '',
				shadeSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4mxy-fffefb0c-e821-44c5-94a8-9b973114d6f8.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG14eS1mZmZlZmIwYy1lODIxLTQ0YzUtOTRhOC05Yjk3MzExNGQ2ZjgucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.jcJ_hsMSgRvP2t9EdYAPSdzuuHJ8Vlo4AG_lMpF5dls',
			},
			{
				name: 'sparkle',
				layer: 'top',
				base: true,
				shade: false,
				baseSrc:
					'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/deq4myw-195f5396-84e0-49cd-8c98-ed6b1710f1c4.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGVxNG15dy0xOTVmNTM5Ni04NGUwLTQ5Y2QtOGM5OC1lZDZiMTcxMGYxYzQucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.fwyjWLYZ7zNKGgs0Ow6Ld_I2X2Jty-veUkUhm-ufTC4',
				shadeSrc: '',
			},
		],
	},
};

function shuffle(array) {
	var currentIndex = array.length,
		randomIndex;

	// While there remain elements to shuffle...
	while (currentIndex != 0) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		];
	}

	return array;
}

const getMarkingNum = () => {
	let markingsNum;
	////
	if (Math.random() <= 0.05) {
		markingsNum = 0;
	} else if (Math.random() < 0.7) {
		if (Math.random() < 0.6) {
			markingsNum = 1;
		} else {
			markingsNum = 2;
		}
	} else {
		if (Math.random() < 0.7) {
			markingsNum = 3;
		} else {
			markingsNum = 4;
		}
	}
	return markingsNum;
};

const getRarity = () => {
	if (Math.random() < 0.8) return 'common';
	else return 'rare';
};

const getMarkings = (species, num, markings) => {
	let k;
	for (k = 0; k < num; k++) {
		const commonMarkings = species.markings.common;
		const rareMarkings = species.markings.rare;
		markings[k] = {};
		markings[k].id = k;
		markings[k].id2 = `${k}${k}`;
		markings[k].color = colors[Math.floor(Math.random() * colors.length)];
		//
		let rarity = getRarity();
		markings[k].rarity = rarity;
		//
		if (markings[k].rarity === 'common') {
			markings[k].type =
				commonMarkings[Math.floor(Math.random() * commonMarkings.length)];
		} else {
			markings[k].type =
				rareMarkings[Math.floor(Math.random() * rareMarkings.length)];
		}
	}
	return markings;
};

const generate = (species) => {
	const dragon = {
		species: species.name,
		markings: [],
		base: {},
		baseSrc: species.baseSrc,
		shadeSrc: species.shadeSrc,
		lineSrc: species.lineSrc
	};
	let markings = [];
	//
	let num = getMarkingNum();
	markings = getMarkings(species, num, markings);

	dragon.markings = markings;
	dragon.base.color = colors[Math.floor(Math.random() * colors.length)];

	// console.log(dragon);
	return dragon;
};

export { generate, mothSilky };
