/** @format */

import React, { Fragment } from 'react';

const TraitsData = ({ traits, traitsData }) => {
	const traitsSection = (area) => {
		return (
			<div>
				<h4>{area.toUpperCase()}</h4>
				<div className='trait'>
					<span>{traits[area][traitsData[area].name].name}</span>
					{traits[area][traitsData[area].name].options.map((el) => (
						<Fragment>
							<span>•</span>
							<span>{traitsData[area][el].selected}</span>
						</Fragment>
					))}
				</div>
			</div>
		);
	};
	return (
		<Fragment>
			{traitsSection('ears')}
            {traitsSection('horns')}
            {traitsSection('tail')}
		</Fragment>
	);
};

export default TraitsData;
