const colors = [
    {
        name: "snow",
        id: 0,
        base: '#FFFFFF',
        shade: '#D3D5DB',
        line: '#CABCC1',
    },
    {
        name: "ivory",
        id: 1,
        base: '#F7EEF1',
        shade: '#CABCC1',
        line: '#AE939C',
    },
    {
        name: "vanilla",
        id: 2,
        base: '#FFE8D7',
        shade: '#E4B7AC',
        line:'#CC9696',
    },
    {
        name: "peach",
        id: 3,
        base: '#FFD7E0',
        shade: '#EBADBB',
        line: '#D87D91',
    },
    {
        name: "dusty",
        id: 4,
        base: '#DCBDBD',
        shade: '#B49595',
        line: '#976A73',
    },
    {
        name: "creamsicle",
        id: 5,
        base: '#FFAD86',
        shade: '#FF8374',
        line: '#E76161',
    },
    {
        name: "jam",
        id: 6,
        base: '#8F0D51',
        shade: '#70003B',
        line: '#4D0129',
    },
    {
        name: "bubblegum",
        id: 7,
        base: '#FF7DC2',
        shade: '#E454A0',
        line: '#D1217D',
    },
    {
        name: "wine",
        id: 8,
        base: '#640035',
        shade: '#32001A',
        line: '#32001A',
    },
    {
        name: "desire",
        id: 9,
        base: '#F03B4E',
        shade: '#AE0339',
        line: '#70003B',
    },
    {
        name: "grape jelly",
        id: 10,
        base: '#544393',
        shade: '#3B2B75',
        line: '#2A1C5F',
    },
    {
        name: "lavender",
        id: 11,
        base: '#C8C8EE',
        shade: '#978CC2',
        line: '#786AAD',
    },
    {
        name: "dandelion",
        id: 12,
        base: '#FBFFBC',
        shade: '#E1C28E',
        line: '#779069',
    },
    {
        name: "seafoam",
        id: 13,
        base: '#58E569',
        shade: '#34B3BA',
        line: '#2179AD',
    },
    {
        name: "sky",
        id: 14,
        base: '#B7F4FF',
        shade: '#64C3E5',
        line: '#4987DC',
    },
    {
        name: "ash",
        id: 15,
        base: '#625B63',
        shade: '#504552',
        line: '#2D2B38',
    },
    {
        name: "space",
        id: 16,
        base: '#2A2F56',
        shade: '#191D3D',
        line: '#0E112B',
    },
    {
        name: "chocolate",
        id: 17,
        base:'#5F1B22',
        shade: '#490E27',
        line: '#34071A',
    },
    {
        name: "squirrel",
        id: 18,
        base: '#B14628',
        shade: '#A53326',
        line: '#811E12',
    },
    {
        name: "matcha",
        id: 19,
        base: '#99E5A9',
        shade:'#5CA48A',
        line: '#2C7D70',
    },
    {
        name: "indigo",
        id: 20,
        base: '#685BD8',
        shade: '#4F40CB',
        line: '#3B2DB6',
    },
    {
        name: "red clay",
        id: 21,
        base: '#B14628',
        shade: '#7E3A28',
        line: '#5A2213',
    },
    {
        name: "raspberry",
        id: 22,
        base: '#FF485B',
        shade: '#C52334',
        line: '#930010',
    },
    {
        name: "light indigo",
        id: 23,
        base: '#8C81ED',
        shade: '#7062E9',
        line: '#5749CF',
    },
    {
        name: "forest",
        id: 24,
        base: '#608864',
        shade: '#3E6543',
        line: '#2B4B39',
    },
    {
        name: "emerald",
        id: 25,
        base: '#29A779',
        shade: '#1E6561',
        line: '#155450',
    },
    {
        name: "light orange",
        id: 26,
        base: '#FF925E',
        shade: '#EF6858',
        line: '#D05445',
    },
    {
        name: "wolf",
        id: 27,
        base: '#A38080',
        shade: '#775E63',
        line: '#5C494D',
    },
    {
        name: "steel",
        id: 28,
        base: '#AFADC1',
        shade: '#737184',
        line: '#545265',
    },
    {
        name: "lemon",
        id: 29,
        base: '#FFD662',
        shade: '#E98F4E',
        line: '#CF6636',
    },
]

module.exports = colors;