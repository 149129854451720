/** @format */

import React, { useState, useEffect } from 'react';

const ColorGrid = ({
	colors,
	filterColors,
	rarity,
	setRarity,
	filter,
	setFilter,
	mutation,
}) => {
	const [filteredMap, setFilteredMap] = useState(null);

	useEffect(() => {
		if (filterColors === true) {
			mutation
				? setFilteredMap(
						colors.filter((item) => {
							return !filter.includes(item.name);
						})
				  )
				: setFilteredMap(
						colors.filter((item) => {
							return !filter.includes(item.name);
						})
				  );
		}
	}, [rarity, filter]);

	const handleFilter = (color) => {
		let newArray;
		let includes = rarity.includes(color);
		includes
			? (newArray = rarity.filter((e) => {
					return e !== color;
			  }))
			: (newArray = (prev) => [...prev, color]);
		setRarity(newArray);
	};

	return (
		<div className='color-grid'>
			{filteredMap &&
				mutation === true &&
				filteredMap.map((color) => (
					<div
						className='color-grid__active'
						style={{
							backgroundColor: color.base,
							borderColor: color.line,
							borderStyle: 'solid',
						}}
					>
						<h5>{color.name}</h5>
					</div>
				))}
			{filteredMap &&
				!mutation &&
				filteredMap.map((color) => (
					<div
						className={rarity.includes(color.name) ? 'color-grid__active' : ''}
						style={{
							backgroundColor: rarity.includes(color.name)
								? color.base
								: color.line,
							borderColor: color.line,
							borderStyle: 'solid',
						}}
						onClick={() => handleFilter(color.name)}
					>
						<h5>{rarity.includes(color.name) ? color.name : ''}</h5>
					</div>
				))}
		</div>
	);
};

export default ColorGrid;
