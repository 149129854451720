/** @format */

import React, { Fragment, useState } from 'react';
import axios from 'axios';

import {
	handleCheck,
	handleNotes,
	inputDouble,
	inputURL,
	inputLock,
} from './helpers.js';

const CreateCharacter = ({
	characterObj,
	setCharacterObj,
	setSearch,
	search,
	file,
	toast
}) => {
	const submitCharacter = (e) => {
		e.preventDefault();
		if (!file) return alert('no file selected');

		const { ownerURLs, designerURLs, artistURLs, orig_acquired, price, MYO } =
			characterObj;

		let data = new FormData();

		// if acquired by sale needs price
		if (orig_acquired === 'sale') {
			if ((price !== 'unknown') & isNaN(price) || null) {
				return alert(`price must be a number or 'unknown'`);
			}
			price === 'unknown'
				? data.append('price', 0)
				: price === null
				? data.append('price', 0)
				: data.append('price', price);
		}

		// if MYO MYO = true
		MYO ? data.append('MYO', true) : data.append('MYO', false);

		// if OwnerURLs, has owner
		ownerURLs
			? data.append('has_owner', true)
			: data.append('has_owner', false);

		const appendArray = [
			'owner',
			'designer',
			'artist',
			'orig_acquired',
			'sale_lock',
			'trade_lock',
			'trade_lock_exp',
			'sale_lock_exp',
			'species',
			'subspecies',
			'notes',
			'rarity',
			'height',
			'width',
		];
		appendArray.forEach((el) => {
			data.append(el, characterObj[el]);
		});

		data.append('ownerURLs', JSON.stringify(ownerURLs));
		data.append('designerURLs', JSON.stringify(designerURLs));
		data.append('artistURLs', JSON.stringify(artistURLs));
		data.append('imgFile', file[0], file.name);

		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/character`)
			: (url = `${process.env.REACT_APP_API}/character`);
		axios({
			method: 'POST',
			url,
			data,
			withCredentials: true,
		})
			.then((res) => {
				toast.success(res.data.message, {
					position: toast.POSITION.TOP_RIGHT,
				});
				console.log(res);
			})
			.catch((error) => {
				console.log(error.response.data.message);
			});
		console.log(data);
	};

	return (
		<form>
			<div className='character-labels'>
				{inputDouble('select', 'make', 'MYO', characterObj, setCharacterObj, [
					[false, 'official'],
					[true, 'MYO'],
				])}
				{inputDouble(
					'select',
					'species',
					'species',
					characterObj,
					setCharacterObj,
					[
						['weaver', 'weaver'],
						['lumi', 'lumi'],
					]
				)}
				{inputDouble(
					'select',
					'subspecies',
					'subspecies',
					characterObj,
					setCharacterObj,
					[
						['silkweaver', 'silkweaver'],
						['toxweaver', 'toxweaver'],
					]
				)}
				{inputDouble(
					'select',
					'rarity',
					'rarity',
					characterObj,
					setCharacterObj,
					[
						['unmutated', 'unmutated'],
						['common', 'common'],
						['uncommon', 'uncommon'],
						['rare', 'rare'],
						['super', 'super'],
						['mythic', 'mythic'],
						['unique', 'unique'],
					]
				)}
				{inputDouble(
					'select',
					'created as',
					'orig_acquired',
					characterObj,
					setCharacterObj,
					[
						['sale', 'sale'],
						['gift', 'gift'],
						['event', 'event'],
						['unknown', 'unknown'],
					]
				)}
				{/* IF SALE, SHOW PRICE */}
				{characterObj.orig_acquired === 'sale' &&
					inputDouble(
						'text',
						'price (USD)',
						'price',
						characterObj,
						setCharacterObj
					)}
				{/* URLs */}
				<span className='chara-br'> </span>
				{inputURL('owner', characterObj, setCharacterObj)}
				<span className='chara-br'> </span> {/*  BREAK */}
				{inputURL('designer', characterObj, setCharacterObj)}
				<span className='chara-br'> </span> {/*  BREAK */}
				{inputURL('artist', characterObj, setCharacterObj)}
				<span className='chara-br'> </span> {/*  BREAK */}
			</div>

			{/* TRANSFER */}
			<div className='transfer'>
				<div className='transfer__checkbox'>
					<input
						type='checkbox'
						className='check'
						onChange={(e) => {
							handleCheck('sale_lock', characterObj, setCharacterObj);
						}}
					/>
					<label>sale locked</label>
				</div>
				<div className='transfer__checkbox'>
					<input
						type='checkbox'
						className='check'
						onChange={(e) => {
							handleCheck('trade_lock', characterObj, setCharacterObj);
						}}
					/>
					<label>trade locked</label>
				</div>
				{/* TRANSFER LOCK EXPIRES */}
				{inputLock('sale', characterObj, setCharacterObj)}
				{inputLock('trade', characterObj, setCharacterObj)}
			</div>
			<textarea
				className='notes'
				type='text'
				placeholder='notes for other admins - users will not be able to see'
				onChange={(e) => {
					handleNotes(e, e.target.value, characterObj);
				}}
				onKeyDown={(e) => {
					if (e.key === 'Tab' && !e.shiftKey) {
						document.execCommand('insertText', false, '\t');
						e.preventDefault();
						return false;
					}
				}}
			></textarea>
			<div className='submit'>
				<button
					className='btn'
					onClick={(e) => {
						submitCharacter(e);
					}}
				>
					<span>SUBMIT CHARACTER</span>
				</button>
			</div>
		</form>
	);
};

export default CreateCharacter;
