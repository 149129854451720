/** @format */

import React, { Fragment, useEffect, useState } from 'react';
import { checkAuth } from './helpers';
import axios from 'axios';

const Verify = ({history, setPage, setTabs, user }) => {
	const [email, setEmail] = useState(null);
	const [verified, setVerified] = useState(null);
	const [code, setCode] = useState(null);
	useEffect(() => {
		handleAuth();
		setPage('VERIFY ACCOUNT');
		setTabs([]);
	}, []);

	const handleAuth = async () => {
        const loggedIn = await checkAuth();
        
        if (loggedIn.loggedIn === false) { history.push('/') } 
        if (loggedIn.isVerified === true) { setTimeout(() => {
            history.push('/')
        }, 3000); } 
		console.log(loggedIn);
		setEmail(loggedIn.email);
		setVerified(loggedIn.isVerified);
	};

	const handleChange = (e) => {
		setCode(e);
	};

	const submitCode = async (e) => {
		const authUser = await checkAuth();
		console.log(authUser);
		const id = authUser.id;

		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/verify`)
			: (url = `${process.env.REACT_APP_API}/verify`);
		axios({
			method: 'PATCH',
			url,
			withCredentials: true,
			data: { id, code },
		})
			.then((res) => {
				console.log(res.data);
				setVerified(true);
				setTimeout(() => {
					history.push('/')
				}, 5000);
			})
			.catch((error) => {
				console.log(error.response.data);
			});
	};
	return (
		<Fragment>
			{verified && <div className='is-verified'>Your account is verified!</div>}
			{email && !verified && (
				<div className='verify'>
					<p className='verify__email'>
						we sent an email to <br></br>
						{email}
					</p>
					<input
						className='verify__code'
						type='text'
						placeholder='enter code'
						onChange={(e) => handleChange(e.target.value)}
					/>
					<button onClick={() => submitCode()}>SUBMIT</button>
					<br></br>
					<p>Didn't get it?</p>
					<button className='verify__resend'>resend email</button>
				</div>
			)}
		</Fragment>
	);
};

export default Verify;
