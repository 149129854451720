/** @format */

import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CreateCharacter from './CreateCharacter';
import EditCharacter from './EditCharacter';
import CharacterImage from './CharacterImage';
import TraitsData from './TraitsData';
import GalleryComponent from 'react-photo-gallery';
import Traits from '../../../components/Traits';
import traits from '../../../data/traits.js';

const Masterlist = () => {
	const [select, setSelect] = useState('create');
	const [details, setDetails] = useState('image');
	const [gallery, setGallery] = useState();
	const [search, setSearch] = useState(false);
	const [filter, setFilter] = useState();
	const [file, setFile] = useState(null);
	const [img, setImg] = useState('');
	const [traitsData, setTraitsData] = useState();
	const [characterData, setCharacterData] = useState();
	const [characterObj, setCharacterObj] = useState({
		owner: '',
		designer: '',
		artist: '',
		ownerURLs: [{ id: 0, site: 'dA', url: '' }],
		designerURLs: [{ id: 0, site: 'dA', url: '' }],
		artistURLs: [{ id: 0, site: 'dA', url: '' }],
		orig_acquired: 'sale',
		trade_lock: false,
		sale_lock: false,
		sale_lock_exp: 'never',
		trade_lock_exp: 'never',
		collection: null,
		species: 'weaver',
		subspecies: 'silkweaver',
		notes: null,
		price: 'unknown',
		height: null,
		width: null,
		MYO: false,
		rarity: 'common',
	});

	useEffect(() => {
		getGallery();
	}, [filter]);

	const createItems = (ml) => {
		ml.sort(function (a, b) {
			if (a.masterlist_id > b.masterlist_id) {
				return -1;
			}
		});
		setGallery(
			ml.map((el) => {
				return {
					height: el.masterlist_img.height,
					width: el.masterlist_img.width,
					src: el.masterlist_img.url,
					id: el._id,
				};
			})
		);
	};

	const clicky = (e) => {
		let img = new Image();
		img.src = window.URL.createObjectURL(e[0]);
		img.onload = () => {
			setCharacterObj((prev) => ({
				...prev,
				height: img.height,
				width: img.width,
			}));
		};

		const ext = e[0].name.match(/\.([^\.]+)$/)[1];

		switch (ext) {
			case 'jpg':
			case 'jpeg':
			case 'png':
				setFile(e);
				setImg(URL.createObjectURL(e[0]));
				break;
			default:
				e = '';
				alert('Only the following file types are allowed: .png, .jpg, .jpeg');
				setFile(null);
		}
	};

	const getGallery = () => {
		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/character/owner/${filter}`)
			: (url = `${process.env.REACT_APP_API}/character/owner/${filter}`);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				createItems(res.data.data);
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
			});
	};

	const getCharacter = (id) => {
		console.log(id);
		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/character/${id}`)
			: (url = `${process.env.REACT_APP_API}/character/${id}`);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				const chara = res.data.character;
				const charaData = {
					owner: chara.owner.name,
					designer: chara.designer.name,
					artist: chara.masterlist_img.artist.name,
					ownerURLs: chara.owner.urls,
					designerURLs: chara.designer.urls,
					artistURLs: chara.masterlist_img.artist.urls,
					height: chara.masterlist_img.height,
					width: chara.masterlist_img.width,
				};
				const dataArray = [
					'orig_acquired',
					'sale_lock',
					'trade_lock',
					'sale_lock_exp',
					'trade_lock_exp',
					'species',
					'subspecies',
					'notes',
					'MYO',
					'rarity',
					'_id'
				];

				// add identical fields to charaData
				dataArray.forEach((el) => {
					charaData[el] = chara[el];
				});
				console.log(charaData);

				if (chara.orig_acquired === 'sale') {
					charaData.price = chara.price;
				} else {
					charaData.price = 0;
				}
				console.log(charaData);
				setCharacterData(charaData);
				setImg(chara.masterlist_img.url)
			})
			.catch((error) => {
				console.log('error');
				console.log(error.response.data.message);
			});
	};

	const handleFilter = (value) => {
		setFilter(value);
	};

	const selectCharacter = (event, photo) => {
		setSearch(false);
		const id = photo.photo.id;
		getCharacter(id);
	};

	const pTab = (setTarget, variable, value) => {
		return (
			<p
				className={variable === value ? 'active' : ''}
				onClick={() => {
					setTarget(value);
				}}
			>
				{value}
			</p>
		);
	};

	return (
		<div className='admin__master'>
			{select && (
				<Fragment>
					{search === true && (
						<div className='character-search'>
							<label className='character-info'>
								<span>owner</span>
								<input
									type='text'
									onChange={(e) => {
										handleFilter(e.target.value);
									}}
								></input>
							</label>
							{gallery && (
								<GalleryComponent
									photos={gallery}
									targetRowHeight={100}
									onClick={selectCharacter}
								/>
							)}
						</div>
					)}
					<div className='admin__master--nav'>
						<div className='admin__master--nav-primary'>
							<h3>
								MASTERLIST <span>•</span>
							</h3>

							{pTab(setSelect, select, 'create')}
							{pTab(setSelect, select, 'edit')}
						</div>
						<div className='admin__master--nav-secondary'>
							{pTab(setDetails, details, 'image')}
							{pTab(setDetails, details, 'traits')}
						</div>
					</div>

					{select && (
						<div className='admin__master--character'>
							{select === 'create' ? (
								<CreateCharacter
									characterObj={characterObj}
									setCharacterObj={setCharacterObj}
									file={file}
									toast={toast}
								/>
							) : (
								<EditCharacter
									characterData={characterData}
									setCharacterData={setCharacterData}
									setSearch={setSearch}
									file={file}
								/>
							)}
							{details === 'image' && (
								<CharacterImage img={img} clicky={clicky} />
							)}
							{details === 'traits' && (
								<div className='admin__master--character-traits'>
									<div className='bg-base radius-default overflow-auto'>
										<Traits
											img={img}
											clicky={clicky}
											setTraitsData={setTraitsData}
										/>
									</div>
									<div className='traits-data'>
										{traitsData && (
											<TraitsData traits={traits} traitsData={traitsData} />
										)}
									</div>
								</div>
							)}
						</div>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default Masterlist;
