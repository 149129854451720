/** @format */

import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';

const Account = ({ user }) => {
	const [connect, setConnect] = useState(false);
	const [connectData, setConnectData] = useState(null);

	useEffect(() => {
		console.log(user);
	}, []);

	const submitDA = () => {
		let url;
		console.log('fetching gallery...');
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/da`)
			: (url = `${process.env.REACT_APP_API}/da`);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				console.log(res);
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
			});
	}

	return (
		<div className='dash__account'>
			<h3 className='dash__account--header'>ACCOUNT</h3>
			<div className='dash__account--auth'>
				<div className='field'>
					<p>username:</p>
					<p>{user.username}</p>
					<button>change</button>
				</div>
				<div className='field'>
					<p>email:</p>
					<p>{user.email}</p>
					<button>change</button>
				</div>
				<div className='field'>
					<p>password:</p>
					<p>****</p>
					<button>change</button>
				</div>
			</div>
			<div className='dash__account--connect'>
				<h3>CONNECT YOUR ACCOUNTS</h3>
				<div
					className='dash__account--connect-sites'
					onClick={() => {
						setConnect(true);
						setConnectData({
							site: 'deviantArt',
							message:
								'this will allow you to import characters from the deviantArt masterlist and submit art from your dA gallery.',
						});
					}}
				>
					<div className='site'>
						<i class='fab fa-deviantart'></i>
						<div>deviantArt</div>
						{user && user.deviantArt === false && (
							<div className='not-connected'>not connected</div>
						)}
					</div>
					<div className='site'>
						<i class='fab fa-twitter'></i>
						<div>twitter</div>
						{user && user.twitter === false && (
							<div className='not-connected'>not connected</div>
						)}
					</div>
					<div className='site'>
						<i class='fab fa-instagram'></i>
						<div>instagram</div>
						{user && user.instagram === false && (
							<div className='not-connected'>not connected</div>
						)}
					</div>
				</div>
			</div>
			<div className='dash__account--delete'>
				<p>DELETE ACCOUNT</p>
			</div>
			{connect && connectData && (
				<div className='dash__account--connectbox'>
					<div className='dash__account--connectbox-inner'>
						<h2>Connect your Hanging Lights account to {connectData.site}?</h2>
						<br></br>
						<h3>{connectData.message}</h3>
						<br></br>
						<button onClick={() => submitDA()}>Connect Accounts</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default Account;
