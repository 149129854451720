/** @format */

import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';

const Login = ({ history, setPage, setIsAuth, setUser, setTabs }) => {
	const navigate = useNavigate();

	const [loginObj, setloginObj] = useState({
		email: 'email',
		password: 'password',
		confirmPassword: 'cPassword',
	});
	useEffect(() => {
		setPage('LOGIN');
		setTabs([])
	}, []);

	const checkFields = () => {

	}

	const login = (e) => {
		const { email, password, confirmPassword } = loginObj;
		e.preventDefault();
		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/login`)
			: (url = `${process.env.REACT_APP_API}/login`);
		axios({
			method: 'POST',
			url,
			withCredentials: true,
			data: { email, password},
		})
			.then((res) => {
                console.log(res.data);
                setIsAuth(true)
				setUser(res.data.data.username)
				navigate('/')
			})
			.catch((error) => {
				toast.error(error)
			});
	};

	const handleChange = (field, value) => {
		setloginObj((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	return (
		<form className='auth'>
			<h3>LOG IN</h3>
			<label>
				{' '}
				<h4>email</h4>
				<input
					type='text'
					className='auth__input'
					onChange={(e) => {
						handleChange('email', e.target.value);
					}}
				/>
			</label>
			<label>
				{' '}
				<h4>password</h4>
				<input
					type='password'
					className='auth__input'
					onChange={(e) => {
						handleChange('password', e.target.value);
					}}
				/>
			</label>
			<button
				className='auth__btn'
				onClick={(e) => {
					login(e);
				}}
			>
				SUBMIT
			</button>
		</form>
	);
};

export default Login;
