/** @format */

import React, { useState, useEffect, Fragment } from 'react';
import Rarity from '../components/Rarity';
import PetCanvas from '../components/PetCanvas/PetCanvas';
import ColorGrid from '../components/ColorGrid';
import color from '../data/colors';

import axios from 'axios';

const PetCustom = ({ setPage, setTabs }) => {
	const [petObj, setPetObj] = useState({
		marking: 'spots',
	});
	const [commonColors, setCommonColors] = useState([
		'vanilla',
		'peach',
		'dandelion',
		'sky',
		'lavender',
	]);
	const [rareColors, setRareColors] = useState(['space', 'jam']);
	const [colorMap, setColorMap] = useState(null);
	const [mainColor, setMainColor] = useState(null);
	const [markingColor, setMarkingColor] = useState(null);
	useEffect(() => {
		setPage('GAME');
		setTabs([]);

		if (!colorMap) {
			getColors();
		}
	}, [commonColors, rareColors]);

	const handleChange = (field, value, setTarget) => {
		setTarget((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	const getColors = () => {
		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/colors`)
			: (url = `${process.env.REACT_APP_API}/colors`);

		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				console.log(res);
				let colorsArray = res.data.data;
				setColorMap(
					colorsArray.sort((a, b) => {
						return a.order - b.order;
					})
				);
				setMainColor(colorsArray[0]);
				setMarkingColor(colorsArray[10]);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const inputDouble = (type, label, value, target, setTarget, options) => {
		return (
			<label className='character-info'>
				<span>{label}</span>
				{type === 'select' ? (
					<select
						value={target[value]}
						onChange={(e) => {
							handleChange(value, e.target.value, setTarget);
						}}
					>
						{options.map((opt) => (
							<option value={opt[0]}>{opt[1]}</option>
						))}
					</select>
				) : (
					<input
						type='text'
						value={target[value]}
						onChange={(e) => {
							handleChange(value, e.target.value, setTarget);
						}}
					></input>
				)}
			</label>
		);
	};

	return (
		<div className='pet-custom'>
			<div className='pet-custom__pet'>
				{colorMap && markingColor && mainColor && (
					<Fragment>
						<PetCanvas
							random={false}
							color={mainColor}
							marking={markingColor}
						/>
					</Fragment>
				)}
				<div className='pet-custom__pet--details'>
					<h3 className='pet-custom__pet--name'>POM</h3>
					<div className='pet-custom__pet--rarity'>
						<Rarity rarity='common'></Rarity>
					</div>
				</div>
				<img
					src='https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/ddmbc10-4cd12a5c-475e-4146-a6e1-35ed16e35e47.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGRtYmMxMC00Y2QxMmE1Yy00NzVlLTQxNDYtYTZlMS0zNWVkMTZlMzVlNDcucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.EQoW_4XQzjw4h01sMlBpi7w4UF5d1_xSVE23QwXt1R0'
					alt=''
				/>
			</div>
			<div className='pet-custom__grid'>
				{colorMap && (
                    <Fragment>
                        <h3>Common Colors</h3>
						<ColorGrid
							colors={colorMap.filter((item) =>
								commonColors.includes(item.name)
							)}
							mainColor
							setMainColor
							markingColor
							setMarkingColor
						/>
					</Fragment>
				)}
            </div>
            <div className='pet-custom__grid'>
				{colorMap && (
                    <Fragment>
                        <h3>Rare Colors</h3>
						<ColorGrid
							colors={colorMap.filter((item) =>
								rareColors.includes(item.name)
							)}
							mainColor
							setMainColor
							markingColor
							setMarkingColor
						/>
					</Fragment>
				)}
			</div>
			<div className='pet-custom__options'>
				{inputDouble('select', 'marking', 'marking', petObj, setPetObj, [
					['spots', 'spots'],
					['tail', 'tail'],
				])}
			</div>
		</div>
	);
};

export default PetCustom;
