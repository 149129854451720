/** @format */

import React from 'react';
import PetCanvas from '../../../components/PetCanvas/PetCanvas';

const EditColor = ({
	editColor,
	setEditColor,
	markingColor,
	petBaseObj,
	selectedColor,
	submitColor,
	createColor,
	setEditWindow,
	pet
}) => {
	return (
		<div className='color-edit'>
			<i
				className='fas fa-times-circle close exit-out color-edit__exit'
				onClick={() => setEditWindow(false)}
			></i>
			<div className='color-edit__container'>
				<div className='name'>
					<label for='name'>NAME</label>
					<input
						type='text'
						id='name'
						value={editColor.name}
						onChange={(e) => {
							setEditColor({ ...editColor, name: e.target.value });
						}}
					></input>
				</div>

				<div className='color-edit__container--example'>
					{petBaseObj && selectedColor && (
						<PetCanvas markings={false} marking={markingColor} color={editColor} pet={pet} />
					)}
				</div>
				<div className='color-edit__container--swatches'>
					<div className='color-edit__container--swatches-swatch'>
						<div for='base'>base</div>
						<input
							type='color'
							id='base'
							name='base'
							value={editColor.base}
							onChange={(e) => {
								setEditColor({
									...editColor,
									base: e.target.value,
								});
							}}
						></input>
					</div>
					<div className='color-edit__container--swatches-swatch'>
						<div for='shade'>shade</div>
						<input
							type='color'
							id='shade'
							name='shade'
							value={editColor.shade}
							onChange={(e) => {
								setEditColor({
									...editColor,
									shade: e.target.value,
								});
							}}
						></input>
					</div>
					<div className='color-edit__container--swatches-swatch'>
						<div for='line'>line</div>
						<input
							type='color'
							id='line'
							name='line'
							value={editColor.line}
							onChange={(e) => {
								setEditColor({
									...editColor,
									line: e.target.value,
								});
							}}
						></input>
					</div>
				</div>
				<div className='color-edit__container--btns'>
					<button
						className='color-edit__container--btn'
						onClick={(e) => submitColor(e, 'edit')}
					>
						SUBMIT EDITED COLOR
					</button>
					<button
						className='color-edit__container--btn'
						onClick={(e) => submitColor(e, 'post')}
					>
						CREATE NEW COLOR
					</button>
				</div>
			</div>
		</div>
	);
};

export default EditColor;
