/** @format */

import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Rarity from '../../../components/Rarity';
import colors from '../../../data/colors';

import PetCanvas from '../../../components/PetCanvas/PetCanvas';

const ItemDetails = ({ marking, base, colors, pet, petObj, setPetObj, submitPet}) => {
	const [rareColors, setRareColors] = useState(null);
	const [commonColors, setCommonColors] = useState(null);
	const [select, setSelect] = useState('common');

	// const inputDouble = (type, label, value, target, setTarget, options) => {
	// 	console.log(`${type}: ${target} ${value}`);
	// 	console.log(target[value]);
	// 	return (
	// 		<label className='character-info'>
	// 			<span>{label}</span>
	// 			{type === 'select' ? (
	// 				<select
	// 					value={target[value]}
	// 					onChange={(e) => {
	// 						handleChange(value, e.target.value, setTarget);
	// 					}}
	// 				>
	// 					{options.map((opt) => (
	// 						<option value={opt[0]}>{opt[1]}</option>
	// 					))}
	// 				</select>
	// 			) : (
	// 				<input
	// 					type='text'
	// 					value={target[value]}
	// 					onChange={(e) => {
	// 						handleChange(value, e.target.value, setTarget);
	// 					}}
	// 				></input>
	// 			)}
	// 		</label>
	// 	);
	// };

	const handleChange = (e, target) => {
		setPetObj((prev) => ({
			...prev,
			[target]: e,
		}));
	};

	return (
		<div className='admin__data--create'>
			{petObj && (
				<Fragment>
					<div className='admin__data--create-header'>
						{marking && base && (
							<PetCanvas
								random={false}
								color={base}
								marking={marking}
								pet={pet}
							/>
						)}
						<div className='admin__data--create-header-info'>
							{petObj.name && (<h3>{petObj.name.toUpperCase()}</h3>)}
							{petObj.rarity && (<Rarity rarity={petObj.rarity} />)}
						</div>
					</div>
					<form action='' className='admin__data--create-form'>
						<div className='admin__data--create-form-options'>
							<label className='admin__data--create-option'>
								<span>name</span>
								<input
									type='text'
									value={petObj.name}
									onChange={(e) => handleChange(e.target.value, 'name')}
								></input>
							</label>
							<label className='admin__data--create-option'>
								<span>rarity</span>
								<select
									value={petObj.rarity}
									onChange={(e) => handleChange(e.target.value, 'rarity')}
								>
									<option value='common'>common</option>
									<option value='uncommon'>uncommon</option>
									<option value='rare'>rare</option>
									<option value='super'>super</option>
									<option value='mythic'>mythic</option>
								</select>
							</label>
						</div>
						<div className="form-submit-btn" onClick={()=> submitPet()}>
							submit
						</div>
					</form>
				</Fragment>
			)}
		</div>
	);
};

export default ItemDetails;
