/** @format */

import React, { Fragment, useEffect, useState } from 'react';
import Summary from './Summary';
import Gallery from './Gallery';
import Characters from './Characters';
import axios from 'axios';

const Profile = ({ setPage, setTabs }) => {
	const [selected, setSelected] = useState('summary');
	const [gallery, setGallery] = useState(null);
	const [characters, setCharacters] = useState(null);
	const [user, setUser] = useState(null);

	useEffect(() => {
		setPage('PROFILE');
		setTabs([]);
		getUser();
		getArt();
		getCharacters();
	}, []);

	const getUser = () => {
		let url;
		console.log('fetching gallery...');
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/user/name/banan`)
			: (url = `${process.env.REACT_APP_API}/user/name/banan`);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				console.log(res.data.data);
				setUser(res.data.data);
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
			});
	};

	const getArt = () => {
		console.log('fetching gallery...');
		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/gallery/sadbloom`)
			: (url = `${process.env.REACT_APP_API}/gallery/sadbloom`);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				createItems(res.data.gallery, setGallery);
			})
			.catch((error) => {
				console.log('error');
				console.log(error.response.data.message);
			});
	};

	const getCharacters = () => {
		let url;
		console.log('fetching gallery...');
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/character`)
			: (url = `${process.env.REACT_APP_API}/character`);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				console.log(res.data.data);
				createCharacters(res.data.data, setCharacters);
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
			});
	};

	const createItems = (gall, state) => {
		state(
			gall.map((el) => {
				return {
					height: el.preview.height,
					width: el.preview.width,
					src: el.preview.src,
				};
			})
		);
	};

	const createCharacters = (gall, state) => {
		state(
			gall.map((el) => {
				return {
					height: el.masterlist_img.height,
					width: el.masterlist_img.width,
					src: el.masterlist_img.url,
				};
			})
		);
	};

	return (
		<Fragment>
			{user && (
				<div className='user-profile'>
					<div className='user-profile__nav'>
						<h4
							className={selected === 'summary' ? 'profile-selected' : ' '}
							onClick={() => {
								setSelected('summary');
							}}
						>
							summary
						</h4>
						<h4
							className={selected === 'characters' ? 'profile-selected' : ' '}
							onClick={() => {
								setSelected('characters');
							}}
						>
							characters
						</h4>
						<h4
							className={selected === 'gallery' ? 'profile-selected' : ' '}
							onClick={() => {
								setSelected('gallery');
							}}
						>
							gallery
						</h4>
						<h4
							className={selected === 'inventory' ? 'profile-selected' : ' '}
							onClick={() => {
								setSelected('inventory');
							}}
						>
							inventory
						</h4>
						<h4
							className={selected === 'quests' ? 'profile-selected' : ' '}
							onClick={() => {
								setSelected('quests');
							}}
						>
							quests
						</h4>

						<h4 className='username'>{user.username.toUpperCase()}</h4>
					</div>
					{gallery && selected === 'summary' && (
						<Summary gallery={gallery.slice(0, 4)} user={user} />
					)}
					{selected === 'gallery' && (
						<Gallery gallery={gallery} size={250} user={user} />
					)}
					{selected === 'characters' && (
						<Characters gallery={characters} size={250} user={user} />
					)}
				</div>
			)}
		</Fragment>
	);
};

export default Profile;
