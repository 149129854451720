/** @format */

import React, { Fragment } from 'react';
import Draggable from 'react-draggable';

import garden from '../img/gardenbasecolor.png';
import pom from '../img/pom.png';
import flitter from '../img/flitter.gif';
import bush from '../img/bush.png';

const Garden = () => {
	const eventControl = (event, info) => {
		event.preventDefault();
		console.log('event name: ', event.type);
		console.log(info);
	};

	return (
		<div className='garden'>
			<img className='garden__base' src={garden} alt='' />
			<Draggable
				onDrag={eventControl}
				onDragEnd={eventControl}
				onStart={eventControl}
				onStop={eventControl}
				onMouseDown={eventControl}
				onMouseUp={eventControl}
				onTouchStart={eventControl}
				onTouchEnd={eventControl}
			>
					<img className='garden__box-1' src={flitter} alt='' />
			</Draggable>
			<Draggable
				onDrag={eventControl}
				onDragEnd={eventControl}
				onStart={eventControl}
				onStop={eventControl}
				onMouseDown={eventControl}
				onMouseUp={eventControl}
				onTouchStart={eventControl}
				onTouchEnd={eventControl}
			>
					<img className='garden__box-2' src={bush} alt='' />
            </Draggable>
            <Draggable
				onDrag={eventControl}
				onDragEnd={eventControl}
				onStart={eventControl}
				onStop={eventControl}
				onMouseDown={eventControl}
				onMouseUp={eventControl}
				onTouchStart={eventControl}
				onTouchEnd={eventControl}
			>
					<img className='garden__box-3' src={bush} alt='' />
			</Draggable>
		</div>
	);
};

export default Garden;
