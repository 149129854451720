/** @format */

const traits = {
	name: 'weaver',
	ears: {
		type: ['round', 'point'],
		round: {
			name: 'round',
			options: ['size', 'inner_ear'],
			size: {
				options: ['small', 'medium'],
				small: 'unmutated',
				medium: 'uncommon',
			},
			inner_ear: {
				options: ['default', 'wrap'],
				default: 'unmutated',
				wrap: 'uncommon',
			},
			src: '../img/ears/UM/round',
			thumb_src: '../img/ears/round-prev.png',
			rarity: 'unmutated',
		},
		point: {
			name: 'point',
			options: ['size', 'inner_ear'],
			size: {
				options: ['small', 'medium'],
				small: 'unmutated',
				medium: 'uncommon',
			},
			inner_ear: {
				options: ['default', 'wrap'],
				default: 'unmutated',
				wrap: 'uncommon',
			},
			src: '../img/ears/UC/point',
			thumb_src: '../img/ears/point-prev.png',
			rarity: 'uncommon',
		},
		// startip: {
		// 	name: 'startip',
		// 	options: ['size', 'inner_ear'],
		// 	size: {
		// 		options: ['small', 'medium'],
		// 		small: 'unmutated',
		// 		medium: 'uncommon',
		// 	},
		// 	inner_ear: {
		// 		options: ['default', 'wrap'],
		// 		default: 'unmutated',
		// 		wrap: 'uncommon',
		// 	},
		// 	src: '../img/ears/UC/startip',
		// 	thumb_src: './img/ears/tipped-prev.png',
		// 	rarity: 'rare',
		// },
	},
	horns: {
		type: ['no', 'standard'],
		no: {
			name: 'no',
			options: [],
			src: 'n/a',
			rarity: 'unmutated',
		},
		standard: {
			name: 'standard',
			options: ['tilt'],
			tilt: {
				options: ['default', 'forward', 'backward'],
				default: 'unmutated',
				forward: 'unmutated',
				backward: 'unmutated'
			},
			
			thumb_src: '../img/horns/standard-prev.png',
			src: '../img/horns/UM/standard',
			rarity: 'unmutated',
		},
	},
	tail: {
		type: ['hair', 'fluff', 'smooth'],
		hair: {
			options: [],
			name: 'hair',
			src: '../img/tail/UM/hair',
			thumb_src: '../img/tail/hair-prev.png',
			rarity: 'unmutated',
		},
		fluff: {
			name: 'fluff',
			options: ['size'],
			size: {
				options: ['small', 'medium'],
				small: 'unmutated',
				medium: 'uncommon',
			},
			src: '../img/tail/UC/fluff',
			thumb_src: '../img/tail/fluff-prev.png',
			rarity: 'uncommon',
		},
		smooth: {
			name: 'smooth',
			options: ['size'],
			size: {
				options: ['small', 'medium'],
				small: 'unmutated',
				medium: 'uncommon',
			},
			src: '../img/tail/UC/smooth',
			thumb_src: '../img/tail/smooth-prev.png',
			rarity: 'uncommon',
		},
	},
};

export default traits;
