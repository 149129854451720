/** @format */

import React, { Fragment, useEffect, useState, useRef } from 'react';
import { generate, mothSilky } from './assets/random';
import pom from '../../data/pom';

const Canvas = (props) => {
	const { setClick, click, random, color, selectedColor, marking, markings, pet} = props;

	const [mainColor, setMainColor] = useState(null);
	const [refresh, setRefresh] = useState(false);

	const canvasRef = useRef(null);
	const canvasRef2 = useRef(null);

	useEffect(() => {
		console.log(marking.name)
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		const canvas2 = canvasRef2.current;
		const ctx2 = canvas2.getContext('2d');

		ctx2.setTransform(1, 0, 0, 1, 0, 0);
		ctx2.clearRect(0, 0, 564, 502);
		ctx.setTransform(1, 0, 0, 1, 0, 0);
		ctx.clearRect(0, 0, 564, 502);
		drawImages(ctx, ctx2);
	}, [props.click, color]);

	function loadImage(url) {
		return new Promise((resolve, reject) => {
			const image = new Image();
			image.addEventListener('load', () => {
				resolve(image);
			});
			image.addEventListener('error', reject);
			image.src = url;
		});
	}

	const getImages = () => {
		let adjustedMarkingLineColor;
		if (marking.order >= color.order) {
			adjustedMarkingLineColor = marking.markingLine
		} else adjustedMarkingLineColor = color.line

		const images = [
			{ src: pet.base, color: color.base, name: 'base' },
			{ src: pet.baseShade, color: color.shade, name: 'shade' },
			{ src: pet.baseLine, color: color.line, name: 'line' },
			{ src: pet.marking, color:  markings ? marking.base : color.base, name: 'markingBase' },
			{ src: pet.markingShade, color:  markings ? marking.shade : color.shade, name: 'markingsShade' },
			{ src: pet.markingLine, color: markings ? adjustedMarkingLineColor : color.line, name: 'markingLine' },
		];
		images.push({ src: pet.line, color: false, name: false });
		console.log(images);
		return images;
	};

	const drawImages = async (ctx, ctx2, canvas2) => {
		const images = getImages();
		for (let i in images) {
			if (images[i] !== '') {
				ctx2.setTransform(1, 0, 0, 1, 0, 0);
				ctx2.clearRect(0, 0, 400, 400);
				const src = images[i].src;
				const color = images[i].color;
				try {
					let image = await loadImage(src);

					if (images[i].color !== false) {
						ctx2.fillStyle = color;
						ctx2.fillRect(0, 0, 400, 400);
						// clip
						ctx2.globalCompositeOperation = 'destination-in';
					}

					ctx2.drawImage(image, 0, 0, image.width, image.height);
					// reset comp. mode to default
					ctx2.globalCompositeOperation = 'source-over';
					ctx.drawImage(canvasRef2.current, 0, 0, 200, 200);
					// reset comp. mode to default
				} catch (error) {
					console.log(`error loading ${images[i]}`);
				}
			}
		}
	};

	return (
		<Fragment>
			<div className=''>
				<canvas className='' ref={canvasRef} width={200} height={200}></canvas>
				<canvas
					className='hidden'
					ref={canvasRef2}
					width={400}
					height={400}
				></canvas>
			</div>
		</Fragment>
	);
};

export default Canvas;
