/** @format */

import React, { useEffect, useState, Fragment } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { checkAuth } from './helpers';

const ProtectedRoute = ({ children }) => {
	const [authStatus, setAuthStatus] = useState(null);
	const [isVerified, setIsVerified] = useState(null);

	useEffect(() => {
		console.log('auth');
		handleAuth();
	}, []);

	const handleAuth = async () => {
		console.log('handleAuth');
		const loggedIn = await checkAuth();
		console.log(loggedIn);
		setAuthStatus(loggedIn.loggedIn);
		setIsVerified(loggedIn.isVerified);
	};

	return (
		<Fragment>
			{authStatus !== null && (
				<Fragment>
					{authStatus ? children : <Navigate to='/' />}
				</Fragment>
			)}
		</Fragment>
	);

	// return (
	// 	<Fragment>
	// 		{authStatus !== null && isVerified!== null &&(
	// 			<Route
	// 				{...rest}
	// 				render={(props) =>
	// 					authStatus && isVerified ? (
	// 						<Component {...rest} {...props} />
	// 					) : authStatus && !isVerified ? (
	// 						<Navigate
	// 							to={{
	// 								pathname: '/verify',
	// 								state: { from: props.location },
	// 							}}
	// 						/>
	// 					) : (<Navigate
	// 						to={{
	// 							pathname: '/login',
	// 							state: { from: props.location },
	// 						}}
	// 					/>)
	// 				}
	// 			/>
	// 		)}
	// 	</Fragment>
	// );
};

export default ProtectedRoute;
