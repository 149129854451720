/** @format */

import React, { Fragment, useEffect } from 'react';

const MARKETPLACE = ({setPage, setTabs}) => {
	useEffect(() => {
		setPage('MARKETPLACE');
		setTabs([]);
	}, []);
	return (
		<div>
			<Fragment>
				<h3>You'll be able to buy and trade stuff here! Someday.</h3>
			</Fragment>
		</div>
	);
};

export default MARKETPLACE;
