/** @format */
import React, { Fragment } from 'react';

export const handleChange = (field, value, setTarget) => {
	setTarget((prev) => ({
		...prev,
		[field]: value,
	}));
};

export const handleCheck = (field, target, setTarget) => {
    setTarget((prev) => ({
        ...prev,
        [field]: !target[field],
    }));
};

export const handleNotes = (e, value, setTarget) => {
    setTarget((prev) => ({
        ...prev,
        notes: value,
    }));
};

const handleURL = (user, field, id, value, target, setTarget) => {
    const userURLs =
        user === 'owner'
            ? 'ownerURLs'
            : user === 'designer'
            ? 'designerURLs'
            : 'artistURLs';
    let items = target[userURLs];
    let item = target[userURLs][id];
    item[field] = value;
    items[id] = item;
    setTarget((prev) => ({
        ...prev,
        [userURLs]: items,
    }));
};

const addUrl = (user, target, setTarget) => {
	const userURLs =
		user === 'owner'
			? 'ownerURLs'
			: user === 'designer'
			? 'designerURLs'
			: 'artistURLs';
	target[userURLs].length >= 4
		? console.log('max number of links')
		: setTarget((prev) => ({
				...prev,
				[userURLs]: [
					...target[userURLs],
					{ id: target[userURLs].length, site: 'dA', url: '' },
				],
		  }));
};

const removeURL = (num, array, target, setTarget) => {
	const userURLs =
		array === 'owner'
			? 'ownerURLs'
			: array === 'designer'
			? 'designerURLs'
			: 'artistURLs';
	setTarget((prev) => ({
		...prev,
		[userURLs]: target[userURLs].slice(
			0,
			target[userURLs].length - 1
		),
	}));
};

export const inputDouble = (type, label, value, target, setTarget, options) => {
	return (
		<label className='character-info'>
			<span>{label}</span>
			{type === 'select' ? (
				<select
					value={target[value]}
					onChange={(e) => {
						handleChange(value, e.target.value, setTarget);
					}}
				>
					{options.map((opt) => (
						<option value={opt[0]}>{opt[1]}</option>
					))}
				</select>
			) : (
				<input
					type='text'
					value={target[value]}
					onChange={(e) => {
						handleChange(value, e.target.value, setTarget);
					}}
				></input>
			)}
		</label>
	);
};

export const inputURL = (user, target, setTarget) => {
	const userURLs =
		user === 'owner'
			? 'ownerURLs'
			: user === 'designer'
			? 'designerURLs'
			: 'artistURLs';
	return (
		<Fragment>
			{inputDouble(user, user, user, target, setTarget)}
			{target[userURLs] &&
				target[userURLs].map(
					(url) =>
						url.id !== 0 && (
							<label className='character-url'>
								<i
									className='fas fa-times admin-exit'
									onClick={() => {
										removeURL(url, user, target, setTarget);
									}}
								></i>
								<div className='character-url-box'>
									<select
										value={url.site}
										onChange={(e) => {
											handleURL(user, 'site', url.id, e.target.value, target, setTarget);
										}}
									>
										<option value='dA'>dA</option>
										<option value='twitter'>twitter</option>
										<option value='instagram'>instagram</option>
										<option value='homepage'>homepage</option>
									</select>
									<input
										type='text'
										value={url.url}
										onChange={(e) => {
											handleURL(user, 'url', url.id, e.target.value, target, setTarget);
										}}
									></input>
								</div>
							</label>
						)
				)}
			<label className='character-url'>
				<i
					className='fas fa-plus url-icon'
					onClick={(e) => {
						addUrl(user, target, setTarget);
					}}
				></i>
				<div className='character-url-box'>
					<select
						value={target[userURLs][0].site}
						onChange={(e) => {
							handleURL(user, 'site', 0, e.target.value, target, setTarget);
						}}
					>
						<option>dA</option>
						<option>twitter</option>
						<option>instagram</option>
						<option>homepage</option>
					</select>{' '}
					<input
						type='text'
						value={target[userURLs][0].url}
						onChange={(e) => {
							handleURL(user, 'url', 0, e.target.value, target, setTarget);
						}}
					></input>
				</div>
			</label>
		</Fragment>
	);
};

export const inputLock = (type, target, setTarget) => {
    const lockType =
        type === 'sale' ? 'sale_lock' : type === 'trade' ? 'trade_lock' : null;
    const lockExp = type === 'trade' ? 'trade_lock_exp' : 'sale_lock_exp';
    return (
        <div
            className={
                target[lockType]
                    ? `transfer__checkbox-exp-active`
                    : `transfer__checkbox-exp-inactive`
            }
        >
            <label>expires</label>
			<select
				
                onChange={(e) => {
                    handleChange(lockExp, e.target.value, setTarget);
                }}
            >
                <option value='never'>never</option>
                <option value='3'>3 months</option>
                <option value='6'>6 months</option>
                <option value='12'>1 year</option>
            </select>
        </div>
    );
};