/** @format */

import axios from 'axios';

export const getColors = async () => {
	let url;
	process.env.NODE_ENV === 'development'
		? (url = `http://localhost:8000/api/colors`)
		: (url = `${process.env.REACT_APP_API}/colors`);

	const data = await axios({
		method: 'GET',
		url,
		withCredentials: true,
	})
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
	return data.data.data.sort((a, b) => {
		return a.order - b.order;
	})
};

export const getRandomColor = (colors) => {
	var currentIndex = colors.length;
	const num = Math.floor(Math.random() * currentIndex);
	return {
		color: colors[num],
		index: num
	}
};
