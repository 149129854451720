/** @format */

import React, { Fragment, useEffect } from 'react';

const Database = ({ setPage, setTabs}) => {
	useEffect(() => {
		setPage('DATABASE');
		setTabs([]);
	}, []);
	return (
		<div>
			<Fragment>
				<h3>SPECIES</h3>
				<h3>PETS</h3>
				<h3>FAMILIARS</h3>
				<h3>ITEMS</h3>
			</Fragment>
		</div>
	);
};

export default Database;
