/** @format */

import React, { useEffect, useState, Fragment } from 'react';
import TraitsComponent from '../components/Traits';

function Traits({ setPage, setTabs, setTraitsData }) {
	// const [select, setSelect] = useState({
	// 	zone: 'ears',
	// 	select: 'type',
	// 	name: null,
	// 	ears: {
	// 		current: null,
	// 	},
	// 	horns: {
	// 		current: null,
	// 	},
	// 	tail: {
	// 		current: null,
	// 	},
	// });

	useEffect(() => {
		setPage('TRAITS');
		setTabs([]);
	}, []);

	return (
		<div className='traits-container'>
			<TraitsComponent setTraitsData={setTraitsData} />
		</div>
	);
}

export default Traits;
