/** @format */

import React, { useEffect, useState } from 'react';
import petimg from '../img/pomm.png';
import famimg from '../img/gar.png';
import unknown from '../img/unknown.png';

const Explore = ({ setPage, setTabs }) => {
	const area =
		'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/dez3fre-c2df2c67-8676-4345-bca4-362720bb4ed4.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGV6M2ZyZS1jMmRmMmM2Ny04Njc2LTQzNDUtYmNhNC0zNjI3MjBiYjRlZDQucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.CpvbyuTTqIcOsb-uWnZjmmFDmSxOCh3K5vCvPzRhABw';
	const encounter =
		'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/ddha31r-41b4a53d-1d08-409e-a74c-53977fbab94e.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGRoYTMxci00MWI0YTUzZC0xZDA4LTQwOWUtYTc0Yy01Mzk3N2ZiYWI5NGUucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.PGtQCGuTimUKkb0AW4rZyaOy5bzsyV6ZR3maHWmJ5Q8';
	const egg =
		'https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/a65b7269-062e-43df-ba67-7d4dbb2fcc23/dd85k0z-a2277d61-c186-4d7d-aa9f-59ea7076144a.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcL2E2NWI3MjY5LTA2MmUtNDNkZi1iYTY3LTdkNGRiYjJmY2MyM1wvZGQ4NWswei1hMjI3N2Q2MS1jMTg2LTRkN2QtYWE5Zi01OWVhNzA3NjE0NGEucG5nIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.NVZIVLANLNoXgGDV_oEPwheo2aiUGmBTOXJYImPrRa4';
	const familiar = famimg;
	const pet = petimg;
	useEffect(() => {
		setPage('GAME');
		setTabs([]);
	}, []);
	return (
		<div className='explore'>
			<div className='explore__container'>
				<img className='explore__image' src={area} alt='' />
				<div className='explore__info'>
					<div className='explore__info--title'>Rewards:</div>
					<div className='explore__info--rewards'>
						{pet && (
							<div className='explore__info--list'>
								<img className='explore__info--reward ' src={pet} alt='' />
								<img className='explore__info--reward ' src={pet} alt='' />
							</div>
						)}
					</div>
					<div className='explore__info--rewards'>
						<div className='explore__info--list'>
							<img className='explore__info--reward' src={familiar} alt='' />
							<img className='explore__info--reward' src={familiar} alt='' />
						</div>
					</div>
					<div className='explore__info--rewards'>
						<img className='explore__info--reward' src={unknown} alt='' />
						<img className='explore__info--reward' src={unknown} alt='' />
						<img className='explore__info--reward' src={unknown} alt='' />
						<img className='explore__info--reward' src={unknown} alt='' />
					</div>
				</div>
				<div className='explore__container--bg'> </div>
			</div>
		</div>
	);
};

export default Explore;
