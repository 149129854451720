/** @format */

import React, { Fragment, useEffect } from 'react';

const Game = ({ setPage, setTabs }) => {
	useEffect(() => {
		setPage('GAME');
		setTabs([]);
	}, []);
	return (
		<div>
			<Fragment>
				<h3>This will have game stuff. Someday.</h3>
			</Fragment>
		</div>
	);
};

export default Game;
