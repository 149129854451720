/** @format */

import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import GalleryComponent from 'react-photo-gallery';
import traits from '../../img/base.png';

import Rarity from '../../components/Rarity';

import gallery2 from 'react-grid-gallery';

const Masterlist = ({ match, setPage, setTabs }) => {
	const [gallery, setGallery] = useState(null);
	const [character, setCharacter] = useState(null);
	const [filter, setFilter] = useState({
		species: 'weaver',
		subspecies: null,
		myo: false,
		rarity: 'all',
	});
	const [select, setSelect] = useState('traits');
	const [prompt, setPrompt] = useState(false);
	const [small, setSmall] = useState(false);

	useEffect(() => {
		setPage('MASTERLIST');
		setTabs([]);
		getGallery();
	}, [filter]);

	const createItems = (ml) => {
		if (ml.length <= 1) {
			setSmall('xs');
		} else if (ml.length <= 2) {
			setSmall('s');
		} else if (ml.length <= 3) {
			setSmall('md');
		} else {
			setSmall(false);
		}

		ml.sort(function (a, b) {
			if (a.masterlist_id > b.masterlist_id) {
				return -1;
			}
		});
		setGallery(
			ml.map((el) => {
				return {
					height: el.masterlist_img.height,
					width: el.masterlist_img.width,
					src: el.masterlist_img.url,
					id: el._id,
				};
			})
		);
	};

	const getGallery = () => {
		console.log('fetching gallery...');
		let url;
		let query = '';
		query = query + 'species=' + filter.species;
		query = query + '&MYO=' + filter.myo;
		if (filter.subspecies) {
			query = query + '&subspecies=' + filter.subspecies;
		}
		if (filter.type) {
			query = query + '&type=' + filter.type;
		}
		if (filter.rarity !== 'all') {
			query = query + '&rarity=' + filter.rarity;
		}

		console.log(query);
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/character?${query}`)
			: (url = `${process.env.REACT_APP_API}/character?${query}`);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				createItems(res.data.data);
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
			});
	};

	const handleChange = (field, value) => {
		setFilter((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	const getCharacter = (id) => {
		console.log(id);
		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/character/${id}`)
			: (url = `${process.env.REACT_APP_API}/character/${id}`);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {
				console.log(res.data);
				setCharacter(res.data.character);
			})
			.catch((error) => {
				console.log('error');
				console.log(error.response.data.message);
			});
	};

	const clicky = (event, photo) => {
		const id = photo.photo.id;
		getCharacter(id);
	};

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
		if (newWindow) newWindow.opener = null;
	};

	return (
		<div className='gallery'>
			{character && (
				<div className='character'>
					{prompt && (
						<div className='character__prompt'>
							The owner of this character is unregistered on this website.{' '}
							<br></br> If you are the owner, you must submit an ownership
							request.<br></br>
							<br></br>Last known username: {character.owner.name}
							<div>
								<div className='character__prompt--button'>
									SUBMIT OWNERSHIP CLAIM
								</div>
								<div
									className='character__prompt--cancel'
									onClick={() => {
										setPrompt(false);
									}}
								>
									cancel
								</div>
							</div>
						</div>
					)}
					<div className='character__box'>
						<i
							className='fas fa-times-circle close exit-out'
							onClick={() => setCharacter(null)}
						></i>
						<div className='character__box--primary'>
							<h3 className='character-id'>{character.masterlist_id}</h3>
							<Rarity rarity={character.rarity} />
							<div className='character-info'>
								<span>species</span>
								<div className='character-info__value'>
									<span>{character.species}</span>
									<span className='subspecies'> • </span>
									<span className='subspecies'>{character.subspecies}</span>
								</div>
							</div>
							<div className='character-info'>
								<div className='user-links'>
									<span>owner</span>
									<div className='icons'>
										<i
											class='fas fa-external-link-alt'
											onClick={() => openInNewTab(character.owner.urls[0].url)}
										></i>
									</div>
								</div>
								<div className='character-info__value'>
									{character.owner.name}
								</div>
							</div>
							<div className='character-info'>
								<div className='user-links'>
									<span>designer</span>
									<div className='icons'>
										<i
											class='fas fa-external-link-alt'
											onClick={() =>
												openInNewTab(character.designer.urls[0].url)
											}
										></i>
									</div>
								</div>
								<div className='character-info__value'>
									{character.designer.name}
								</div>
							</div>
							{/* <div className='character-lock'>can be traded</div>
							<div className='character-lock'>can be sold</div> */}
							<div
								className='character-offer clickable'
								onClick={() => setPrompt(true)}
							>
								<span>claim character</span>
							</div>
							<div className='character-nft'>
								<span>not for trade</span> • <span>do not contact</span>
							</div>
							<div className='character-nft'>
								<span>not for trade</span> • <span>do not contact</span>
							</div>
							{/* <div className='character-transfer'>
								<span>for trade</span>
								<span className='transfer-detail'>wishlist</span>
							</div>
							<div className='character-transfer'>
								<span>for sale</span>
								<span className='transfer-detail'>price</span>
							</div> */}
						</div>
						<div className='character__box--secondary'>
							<div className='character__box--secondary-select'>
								<h3
									className={`character__box--secondary-select${
										select === 'traits' ? '-active' : ''
									}`}
									onClick={() => {
										setSelect('traits');
									}}
								>
									traits
								</h3>
								<h3
									className={`character__box--secondary-select${
										select === 'ownership' ? '-active' : ''
									}`}
									onClick={() => {
										setSelect('ownership');
									}}
								>
									ownership
								</h3>
							</div>
							{select === 'traits' && (
								<div className='character__box--secondary-details'>traits</div>
							)}
							{select === 'ownership' && (
								<div className='character__box--secondary-details'>
									<div className='label'>current owner</div>
									<div className='current-owner'>{character.owner.name}</div>
									<div className='label-type'>
										<i class='fas fa-arrow-up'></i>
										<span>trade</span>
									</div>
									<div className='past-owner'>(prevOwner)</div>
									<div className='label-type'>
										<i class='fas fa-arrow-up'></i>
										<span>sale: $100</span>
									</div>
									<div className='past-owner'>(prevOwner)</div>
									<div className='label-type'>
										<i class='fas fa-arrow-up'></i>
										<span>trade</span>
									</div>
									<div className='past-owner'>(prevOwner)</div>
									<div className='label-type'>
										<i class='fas fa-arrow-up'></i>
										<span>trade</span>
									</div>
									<div className='past-owner'>(prevOwner)</div>
									<div className='label-type'>
										<i class='fas fa-arrow-up'></i>
										<span>trade</span>
									</div>
									<div className='past-owner'>(prevOwner)</div>
									<div className='label-type'>
										<i class='fas fa-arrow-up'></i>
										<span>trade</span>
									</div>
									<div className='past-owner'>(prevOwner)</div>
									<div className='label'>original acquisition</div>
									<div className='current-owner'>sale: $100</div>
								</div>
							)}
						</div>
						<div className='character__box--image'>
							<img src={character.masterlist_img.url} alt='' />
							<div
								className='credit'
								onClick={() =>
									openInNewTab(character.masterlist_img.artist.urls[0].url)
								}
							>
								artist: {character.masterlist_img.artist.name}{' '}
								<i class='fas fa-external-link-alt'></i>
							</div>
						</div>
					</div>
				</div>
			)}
			<div className='gallery__filter'>
				<label className='gallery__filter--select' for=''>
					<span>species</span>
					<select
						name=''
						id=''
						onChange={(e) => {
							handleChange('species', e.target.value);
						}}
					>
						<option value='weaver'>weaver</option>
						<option value='lumi'>lumi</option>
					</select>
				</label>
				<label className='gallery__filter--select' for=''>
					<span>subspecies</span>
					<select
						name=''
						id=''
						onChange={(e) => {
							handleChange('subspecies', e.target.value);
						}}
					>
						<option value=''>all</option>
						<option value='silkweaver'>silkweaver</option>
						<option value='toxweaver'>toxweaver</option>
					</select>
				</label>
				<label className='gallery__filter--select' for=''>
					<span>type</span>
					<select
						name=''
						id=''
						onChange={(e) => {
							handleChange('myo', e.target.value);
						}}
					>
						<option value={false}>official</option>
						<option value={true}>MYO</option>
					</select>
				</label>
				<label className='gallery__filter--select' for=''>
					<span>rarity</span>
					<select
						name=''
						id=''
						onChange={(e) => {
							handleChange('rarity', e.target.value);
						}}
					>
						<option value={null}>all</option>
						<option value='unmutated'>unmutated</option>
						<option value='common'>common</option>
						<option value='uncommon'>uncommon</option>
						<option value='rare'>rare</option>
						<option value='super'>super</option>
						<option value='mythic'>mythic</option>
						<option value='unique'>unique</option>
					</select>
				</label>

				<div className='transfer-filter'>
					<div className='transfer-text'>available for:</div>

					<label
						className='gallery__filter--check-tab check-tab'
						id='trade-check'
					>
						<input type='checkbox' />
						<p className='check-tab__text'>trade</p>
						<span className='checkmark'></span>
					</label>

					<label
						className='gallery__filter--check-tab check-tab'
						id='sale-check'
					>
						<input type='checkbox' />
						<p className='check-tab__text'>sale</p>
						<span className='checkmark'></span>
					</label>
				</div>
			</div>
			<div className='gallery__component'>
				{gallery && (
					<div
						className={`gallery__view ${
							small === 'xs'
								? 'xs-gallery'
								: small === 's'
								? 's-gallery'
								: small === 'md'
								? 'md-gallery'
								: ''
						} `}
					>
						<div className='gallery__view--images'>
							<GalleryComponent
								photos={gallery}
								targetRowHeight={250}
								onClick={clicky}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Masterlist;
