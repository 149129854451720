/** @format */

import React, { Fragment, useState } from 'react';
import axios from 'axios';
import {
	handleCheck,
	handleNotes,
	inputDouble,
	inputURL,
	inputLock,
} from './helpers.js';

const EditCharacter = ({
	characterData,
	setCharacterData,
	submitCharacter,
	setSearch,
	search,
	file,
}) => {
	const updateCharacter = (e) => {
		e.preventDefault();

		const { ownerURLs, designerURLs, artistURLs, orig_acquired, price, MYO } =
			characterData;

		let data = new FormData();

		// if acquired by sale needs price
		if (orig_acquired === 'sale') {
			if ((price !== 'unknown') & isNaN(price) || null) {
				return alert(`price must be a number or 'unknown'`);
			}
			price === 'unknown'
				? data.append('price', 0)
				: price === null
				? data.append('price', 0)
				: data.append('price', price);
		}

		// if MYO MYO = true
		MYO ? data.append('MYO', true) : data.append('MYO', false);

		// if OwnerURLs, has owner
		ownerURLs
			? data.append('has_owner', true)
			: data.append('has_owner', false);

		const appendArray = [
			'owner',
			'designer',
			'artist',
			'orig_acquired',
			'sale_lock',
			'trade_lock',
			'trade_lock_exp',
			'sale_lock_exp',
			'species',
			'subspecies',
			'notes',
			'rarity',
			'height',
			'width',
		];
		appendArray.forEach((el) => {
			data.append(el, characterData[el]);
		});

		data.append('ownerURLs', JSON.stringify(ownerURLs));
		data.append('designerURLs', JSON.stringify(designerURLs));
		data.append('artistURLs', JSON.stringify(artistURLs));
		if (file) {
			data.append('imgFile', file[0], file.name);
		}

		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/character/${characterData._id}`)
			: (url = `${process.env.REACT_APP_API}/character/${characterData._id}`);
		axios({
			method: 'PATCH',
			url,
			data,
			withCredentials: true,
		})
			.then((res) => {
				alert(
					`success! Character updated.`
				);
				console.log(res);
			})
			.catch((error) => {
				console.log(error.response.data.message);
			});
		console.log(data);
	};
	return (
		<form>
			<div className='character-labels'>
				<div
					className='character-select'
					onClick={(e) => {
						setSearch(true);
					}}
				>
					SEARCH CHARACTERS
				</div>
				<span className='chara-br'> </span> {/*  BREAK */}
				{characterData && (
					<Fragment>
						{' '}
						{inputDouble(
							'select',
							'make',
							'MYO',
							characterData,
							setCharacterData,
							[
								[false, 'official'],
								[true, 'MYO'],
							]
						)}
						{inputDouble(
							'select',
							'species',
							'species',
							characterData,
							setCharacterData,
							[
								['weaver', 'weaver'],
								['lumi', 'lumi'],
							]
						)}
						{inputDouble(
							'select',
							'subspecies',
							'subspecies',
							characterData,
							setCharacterData,
							[
								['silkweaver', 'silkweaver'],
								['toxweaver', 'toxweaver'],
							]
						)}
						{inputDouble(
							'select',
							'rarity',
							'rarity',
							characterData,
							setCharacterData,
							[
								['unmutated', 'unmutated'],
								['common', 'common'],
								['uncommon', 'uncommon'],
								['rare', 'rare'],
								['super', 'super'],
								['mythic', 'mythic'],
								['unique', 'unique'],
							]
						)}
						{inputDouble(
							'select',
							'created as',
							'orig_acquired',
							characterData,
							setCharacterData,
							[
								['sale', 'sale'],
								['gift', 'gift'],
								['event', 'event'],
								['unknown', 'unknown'],
							]
						)}
						{/* IF SALE, SHOW PRICE */}
						{characterData.orig_acquired === 'sale' &&
							inputDouble(
								'text',
								'price (USD)',
								'price',
								characterData,
								setCharacterData
							)}
						{/* URLs */}
						<span className='chara-br'> </span>
						{inputURL('owner', characterData, setCharacterData)}
						<span className='chara-br'> </span> {/*  BREAK */}
						{inputURL('designer', characterData, setCharacterData)}
						<span className='chara-br'> </span> {/*  BREAK */}
						{inputURL('artist', characterData, setCharacterData)}
						<span className='chara-br'> </span> {/*  BREAK */}
					</Fragment>
				)}
			</div>
			{characterData && (
				<Fragment>
					<div className='transfer'>
						<div className='transfer__checkbox'>
							<input
								type='checkbox'
								className='check'
								checked={characterData.sale_lock}
								onChange={(e) => {
									handleCheck('sale_lock', characterData, setCharacterData);
								}}
							/>
							<label>sale locked</label>
						</div>
						<div className='transfer__checkbox'>
							<input
								type='checkbox'
								className='check'
								checked={characterData.trade_lock}
								onChange={(e) => {
									handleCheck('trade_lock', characterData, setCharacterData);
								}}
							/>
							<label>trade locked</label>
						</div>
						{/* TRANSFER LOCK EXPIRES */}
						{inputLock('sale', characterData, setCharacterData)}
						{inputLock('trade', characterData, setCharacterData)}
					</div>
					<textarea
						className='notes'
						type='text'
						placeholder='notes for other admins - users will not be able to see'
						onChange={(e) => {
							handleNotes(e, e.target.value, characterData);
						}}
						onKeyDown={(e) => {
							if (e.key === 'Tab' && !e.shiftKey) {
								document.execCommand('insertText', false, '\t');
								e.preventDefault();
								return false;
							}
						}}
					></textarea>
					<div className='submit'>
						<button
							className='btn'
							onClick={(e) => {
								updateCharacter(e);
							}}
						>
							<span>UPDATE CHARACTER</span>
						</button>
					</div>
				</Fragment>
			)}
			{/* TRANSFER */}
		</form>
	);
};

export default EditCharacter;
