/** @format */

import axios from 'axios';

/** @format */

const isLoggedIn = async () => {
    console.log('check');
    const userObj = {};
	let url;
	process.env.NODE_ENV === 'development'
		? (url = `http://localhost:8000/api/authenticate`)
		: (url = `${process.env.REACT_APP_API}/authenticate`);
	const loggedIn = await axios({
		method: 'GET',
		url,
		withCredentials: true,
	})
		.then((res) => {
			userObj.username = res.data.username
			userObj.role = res.data.role
			userObj.isVerified = res.data.isVerified
			userObj.email = res.data.email
			userObj.id = res.data.id
			return true;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});

	userObj.loggedIn = loggedIn;
    return userObj
};

export const checkAuth = async () => {
    return await isLoggedIn()
};
