/** @format */

import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signup = ({ history, setPage, setIsAuth, setUser, setTabs }) => {
	const [SignupObj, setSignupObj] = useState({
		username: '',
		email: '',
		password: '',
		passwordConfirm: '',
		registrationKey: '',
	});
	const [emailTaken, setEmailTaken] = useState(false);
	const [userTaken, setUserTaken] = useState(false);
	const [userLength, setUserLength] = useState(false);
	const [emailValid, setEmailValid] = useState(false);
	const [emailBlank, setEmailBlank] = useState(true);
	const [usernameBlank, setUsernameBlank] = useState(true);
	useEffect(() => {
		setPage('SIGNUP');
		setTabs([]);
		checkFields();
	}, [SignupObj]);

	const checkFields = () => {
		const { username, email } = SignupObj;
		username.length === 0 ? setUsernameBlank(true) : setUsernameBlank(false);
		email.length === 0 ? setEmailBlank(true) : setEmailBlank(false);
		username.length <= 3 ? setUserLength(false) : setUserLength(true);
		validateEmail() ? setEmailValid(true) : setEmailValid(false);

		let url;
		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/checkFields`)
			: (url = `${process.env.REACT_APP_API}/checkFields`);
		axios({
			method: 'PATCH',
			url,
			withCredentials: true,
			data: { username, email },
		})
			.then((res) => {
				console.log(res);
				setUserTaken(res.data.userTaken);
				setEmailTaken(res.data.emailTaken);
			})
			.catch((error) => {
				console.log(error.response.data);
			});
	};

	const validateEmail = () => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(SignupObj.email)) {
			return true;
		}
		return false;
	};
	const toasty = () => {
		console.log('toasty');
		toast.error('Success Notification !', {
			position: toast.POSITION.TOP_RIGHT,
		});
	};

	const Signup = (e) => {
		e.preventDefault();
		const { username, email, password, passwordConfirm, registrationKey } =
			SignupObj;

		if (
			username === '' ||
			email === '' ||
			password === '' ||
			passwordConfirm === '' ||
			registrationKey === ''
		) {
			toast.error('Missing Fields');
		} else {
			if (registrationKey === 'superkey') {
				let url;
				process.env.NODE_ENV === 'development'
					? (url = `http://localhost:8000/api/signup`)
					: (url = `${process.env.REACT_APP_API}/signup`);
				axios({
					method: 'POST',
					url,
					withCredentials: true,
					data: { username, email, password, passwordConfirm },
				})
					.then((res) => {
						console.log(res);
						setIsAuth(true);
						history.push('/verify');
					})
					.catch((error) => {
						console.log(error.response.data);
						toast.error(error.response.data.message, {
							position: toast.POSITION.TOP_RIGHT,
						});
					});
			} else toast.error('Invalid Registration key');
		}
	};

	const handleChange = (field, value) => {
		setSignupObj((prev) => ({
			...prev,
			[field]: value,
		}));
	};

	return (
		<Fragment>
			<form className='auth'>
				<h3
					onClick={() => {
						toasty();
					}}
				>
					CREATE ACCOUNT
				</h3>
				<label>
					{' '}
					<h4>username</h4>
					<input
						type='text'
						className='auth__input'
						onChange={(e) => {
							handleChange('username', e.target.value);
						}}
					/>
					<p
						className={
							usernameBlank
								? 'blank'
								: !userTaken && userLength
								? 'available'
								: 'taken'
						}
					>
						{usernameBlank
							? ''
							: !userTaken && userLength
							? 'available'
							: userTaken && userLength
							? 'taken'
							: 'too short'}
					</p>
				</label>
				<label>
					{' '}
					<h4>email</h4>
					<input
						type='text'
						className='auth__input'
						onChange={(e) => {
							handleChange('email', e.target.value);
						}}
					/>
					<p
						className={
							emailBlank
								? 'blank'
								: !emailTaken && emailValid
								? 'available'
								: 'taken'
						}
					>
						{emailBlank
							? ''
							: !emailTaken && emailValid
							? 'available'
							: emailTaken && emailValid
							? 'taken'
							: 'invalid'}
					</p>
				</label>
				<label>
					{' '}
					<h4>password</h4>
					<input
						type='password'
						className='auth__input'
						onChange={(e) => {
							handleChange('password', e.target.value);
						}}
					/>
				</label>
				<label>
					{' '}
					<h4>confirm password</h4>
					<input
						type='password'
						className='auth__input'
						onChange={(e) => {
							handleChange('passwordConfirm', e.target.value);
						}}
					/>
				</label>
				<label>
					{' '}
					<h4>registration key</h4>
					<input
						type='text'
						className='auth__input'
						onChange={(e) => {
							handleChange('registrationKey', e.target.value);
						}}
					/>
				</label>
				<button
					className='auth__btn'
					onClick={(e) => {
						Signup(e);
					}}
				>
					REGISTER
				</button>
			</form>
		</Fragment>
	);
};

export default Signup;
