/** @format */

import React, { useEffect, useState, Fragment } from 'react';
import GalleryComponent from 'react-photo-gallery';
import axios from 'axios';
import Masterlist from './MasterlistMini';

const Claim = ({ setPage, setTabs }) => {
	const [activeSelect, setActiveSelect] = useState(false);
	const [noSelected, setNoSelected] = useState(true);
	const [characters, setCharacters] = useState([]);
	const [selectedCharacters, setSelectedCharacters] = useState([]);
	const [example, setExample] = useState(null);
	const [ownsDA, setownsDA] = useState(true);
	useEffect(() => {
		setPage('SUBMIT OWNERSHIP');
		setTabs([]);
	}, []);

	const getGallery = () => {
		console.log('fetching gallery...');
		let url;
		let query = '';

		process.env.NODE_ENV === 'development'
			? (url = `http://localhost:8000/api/character?${query}`)
			: (url = `${process.env.REACT_APP_API}/character?${query}`);
		axios({
			method: 'GET',
			url,
			withCredentials: true,
		})
			.then((res) => {})
			.catch((error) => {
				console.log('error');
				console.log(error);
			});
	};

	return (
		<Fragment>
			<div className='claim'>
				<div className='claim__selected'>
					<h3>Characters to claim:</h3>
					{selectedCharacters.length === 0 && (<div className='no-selected'>no characters selected</div>)}
					{selectedCharacters.length !== 0 && (<GalleryComponent photos={selectedCharacters} targetRowHeight={200} />)}
					<div className='claim__selected--button' onClick={() => setActiveSelect(true)}>ADD CHARACTERS</div>
				</div>
				{activeSelect && (
					<div className='claim__masterlist'>
						<Masterlist
							characters={characters}
							setCharacters={setCharacters}
							selectedCharacters={selectedCharacters}
							setSelectedCharacters={setSelectedCharacters}
							setActiveSelect={setActiveSelect}
						/>
					</div>
				)}

				<div className='claim__info'>
					<h3>
						To claim a character, you will need proof of ownership. Please
						select:
					</h3>
					<br></br>
					<div
						className={`claim__info--select ${
							ownsDA === true ? `claim-selected` : `claim-deselected`
						}`}
					>
						I own the deviantArt account that this character is listed under.
					</div>
					<div
						className={`claim__info--select ${
							ownsDA === false ? `claim-selected` : `claim-deselected`
						}`}
					>
						I do not own the deviantArt account that this character is listed
						under.
					</div>
					<br></br>
				</div>
				<div className='claim__form'>
					<div className='form'>
						<form>
							<div class='form-labels'>
								<label class='form-info'>
									<span>username</span>
									<input placeholder='enter username'></input>
								</label>
								<label class='form-info'>
									<span>link to dA</span>
									<input placeholder='enter link'></input>
								</label>
							</div>
						</form>
					</div>
				</div>
				<br></br>
				<div className='claim__info'>
					<h3>
						Send a DM to bananDex (link: ) from the dA account associated with
						these characters to claim them, then submit this form below.
					</h3>
					<br></br>
					<h3>
						A moderator will review your claim within 48 hours. <br></br>You can
						view pending requests on your <a href='/dash'>dashboard</a>.
					</h3>
					<br></br>
					<div className='claim__info--submit claim-selected'>SUBMIT FORM</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Claim;
