/** @format */

import React, { useState, Fragment } from 'react';
import GalleryComponent from 'react-photo-gallery';

const Gallery = ({ match, setPage, setTabs, gallery, size }) => {
	return (
		<Fragment>
			{gallery && (
				<div className='gallery__view'>
					<div className='gallery__view--images'>
						<GalleryComponent photos={gallery} targetRowHeight={size} />
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default Gallery;
