/** @format */

import React, { Fragment, useState, useEffect } from 'react';

const Rarity = ({ rarity }) => {
	const [greyStars, setGreyStars] = useState(null);
	const [stars, setStars] = useState(null);

	const starNum =
		rarity === 'unmutated'
			? 0
			: rarity === 'common'
			? 1
			: rarity === 'uncommon'
			? 2
			: rarity === 'rare'
			? 3
			: rarity === 'super'
			? 4
			: 5;

	const greyNum = 5 - starNum;
	let starArray = Array.apply(null, { length: starNum }).map(
		Number.call,
		Number
	);
	let greyArray = Array.apply(null, { length: greyNum }).map(
		Number.call,
		Number
	);

	useEffect(() => {
		console.log(starNum);
	}, []);

	return (
		<Fragment>
			<span className='rarity-display'>
				<div className={`rarity-display__stars ${rarity}`}>
					{starArray.map((item) => {
						return <i class='fas fa-star'></i>;
					})}
				</div>
				<div className='rarity-display__stars grey'>
					{greyArray.map((item) => {
						return <i class='fas fa-star'></i>;
					})}
				</div>
                <span className={`rarity-name ${rarity}-text`}>{rarity}</span>
			</span>
			{/* <span className='rarity-display'>
				<div className='rarity-display__stars grey'>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
                    <i class='fas fa-star'></i>
                    <i class='fas fa-star'></i>
				</div>
				<span className='rarity-name unmutated-text'>unmutated</span>
			</span>
			<span className='rarity-display'>
				<div className='rarity-display__stars common'>
					<i class='fas fa-star'></i>
				</div>
				<div className='rarity-display__stars grey'>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
                    <i class='fas fa-star'></i>
                    <i class='fas fa-star'></i>
				</div>
				<span className='rarity-name common-text'>common</span>
			</span>
			<span className='rarity-display'>
				<div className='rarity-display__stars uncommon'>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
				</div>
				<div className='rarity-display__stars grey'>
					<i class='fas fa-star'></i>
                    <i class='fas fa-star'></i>
                    <i class='fas fa-star'></i>
				</div>
				<span className='rarity-name uncommon-text'>uncommon</span>
			</span>
			<span className='rarity-display'>
				<div className='rarity-display__stars rare'>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
                    <i class='fas fa-star'></i>
				</div>
				<div className='rarity-display__stars grey'>
                    <i class='fas fa-star'></i>
                    <i class='fas fa-star'></i>
				</div>
				<span className='rarity-name rare-text'>rare</span>
			</span>
			<span className='rarity-display'>
				<div className='rarity-display__stars super'>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
                    <i class='fas fa-star'></i>
				</div>
				<div className='rarity-display__stars grey'>
                    <i class='fas fa-star'></i>
				</div>
				<span className='rarity-name super-text'>super</span>
			</span>
			<span className='rarity-display'>
				<div className='rarity-display__stars mythic'>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
				</div>
				<span className='rarity-name mythic-text'>mythic</span>
			</span>
			<span className='rarity-display'>
				<div className='rarity-display__stars unique'>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
					<i class='fas fa-star'></i>
				</div>
				<span className='rarity-name unique-text'>unique</span>
			</span> */}
		</Fragment>
	);
};

export default Rarity;
